import { AlbumsService } from './../../core/services/albums.service';
import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-uploader',
	templateUrl: './uploader.component.html',
	styleUrls: ['./uploader.component.css']
})
export class UploaderComponent implements OnInit {

	private album: any;
	public fileTasks = []

	get albumKey() {		
		if(this.album) {
			return this.album.albumRef.key;
		} else {
			return null;
		}
	}

	isUploading = false;

	constructor(private albumsService: AlbumsService) { }

	ngOnInit() {
	}

	filesChanged(event) {
		this.isUploading = true;		
		if (event.srcElement.files.length) {
			if (this.album) {
				let fileTasks = this.albumsService.addFilesToAlbum(this.album.albumRef.key, event.srcElement.files);
				Promise.all(fileTasks)
					.then(() => this.isUploading = false);
				this.fileTasks = this.fileTasks.concat(fileTasks);
			} else {
				this.album = this.albumsService.create('test', event.srcElement.files);
				Promise.all(this.album.fileTasks.map(task => task.task))
					.then(() => this.isUploading = false);
				this.fileTasks = this.fileTasks.concat(this.album.fileTasks);
			}
        }
        
        event.srcElement.value = '';
	}

	removeFile(file) {	
		var x = this.fileTasks.indexOf(file);
		this.fileTasks.splice(x, 1);

		// ensure album does not persist if there are no items left
		if (this.fileTasks.length == 0)
			this.album = null;

		var sFileRef = file.fileRef.toString();
		var n = sFileRef.indexOf("/albums");  // start file reference after the ".com"
		sFileRef = sFileRef.substr(n);
		this.albumsService.removeItemFromAlbum(sFileRef);	
	}

	clear() {
		this.fileTasks = [];
		this.album = null;
	}
}
