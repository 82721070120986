import { SharedModule } from 'app/shared/shared.module';
import { PeopleModule } from 'app/people/people.module';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MomentModule } from 'angular2-moment';
import { SideSubMenuComponent } from './side-sub-menu/side-sub-menu.component';

@NgModule({
	imports: [
		CommonModule,
		BsDropdownModule.forRoot(),
		RouterModule.forChild([]),
        PeopleModule,
        MomentModule,
        SharedModule
	],
	exports: [MainMenuComponent, SideSubMenuComponent],
	declarations: [MainMenuComponent, SideSubMenuComponent]
})
export class MenusModule { }
