import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploaderComponent } from './uploader/uploader.component';
import { FileTypePlaceholderComponent } from './file-type-placeholder/file-type-placeholder.component';
import { ProgressbarModule } from 'ngx-bootstrap';
import { AlbumCoverComponent } from './album-cover/album-cover.component';
import { SharedModule } from "app/shared/shared.module";
import { AlbumCoverImageSrcDirective } from './directives/album-cover-image-src.directive';
import { TooltipModule } from 'ngx-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        TooltipModule,
		ProgressbarModule.forRoot()
    ],
    declarations: [UploaderComponent, FileTypePlaceholderComponent, AlbumCoverComponent, AlbumCoverImageSrcDirective],
    exports: [UploaderComponent, AlbumCoverComponent, AlbumCoverImageSrcDirective]
})
export class FilesModule { }
