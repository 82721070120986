import { FilesModule } from './../files/files.module';
import { CommentsModule } from 'app/comments/comments.module';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiscussionComponent } from './discussion/discussion.component';
import { DiscussionListComponent } from './discussion-list/discussion-list.component';
import { DiscussionCreateComponent } from './discussion-create/discussion-create.component';
import { DiscussionService } from "app/discussion/discussion.service";
import { DiscussionRoutingModule } from "app/discussion/discussion-routing.module";
import { NeighborhoodModule } from "app/neighborhood/neighborhood.module";
import { DiscussionItemComponent } from './discussion-item/discussion-item.component';
import { DiscussionItemModalComponent } from './discussion-item-modal/discussion-item-modal.component'
import { PeopleModule } from "app/people/people.module";
import { GroupsModule } from "app/groups/groups.module";
import { TooltipModule, BsDropdownModule } from 'ngx-bootstrap';
import { DiscussionTagsComponent } from './discussion-tags/discussion-tags.component';
import { SharedModule } from "app/shared/shared.module";
import { DiscussionRecentImagesComponent } from './discussion-recent-images/discussion-recent-images.component';
import { MenusModule } from "app/menus/menus.module";
import { RlTagInputModule } from 'app/shared/tag-input/tag-input.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DiscussionRoutingModule,
		NeighborhoodModule,
		PeopleModule,
		CommentsModule,
		FilesModule,
		RlTagInputModule,
		GroupsModule,
		SharedModule,
		MenusModule,
		BrowserAnimationsModule,
		BsDatepickerModule.forRoot(),
		TimepickerModule.forRoot(),
		TooltipModule.forRoot(),
		BsDropdownModule.forRoot()
	],
	declarations: [DiscussionComponent, DiscussionListComponent, DiscussionCreateComponent, DiscussionItemComponent, DiscussionItemModalComponent, DiscussionTagsComponent, DiscussionRecentImagesComponent],
	exports: [DiscussionComponent, DiscussionItemComponent, DiscussionCreateComponent],
	providers: [DiscussionService]
})
export class DiscussionModule { }
