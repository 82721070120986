import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
    selectedItem = 'what'

    current = 0;
    items: Array<any>;

    constructor(private titleService: Title) {
        titleService.setTitle('Wissle FAQ');
     }

    ngOnInit() {
    }


}
