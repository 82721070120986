import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeaturesRoutingModule } from './features-routing.module';
import { FeatureAlertsComponent } from "app/marketing/features/feature-alerts/feature-alerts.component";
import { FeatureDiscussionBoardComponent } from "app/marketing/features/feature-discussion-board/feature-discussion-board.component";
import { FeaturePricingComponent } from "app/marketing/features/feature-pricing/feature-pricing.component";
import { FeatureMembersComponent } from "app/marketing/features/feature-members/feature-members.component";

@NgModule({
  imports: [
    CommonModule,
    FeaturesRoutingModule
  ],
  declarations: [FeatureAlertsComponent, FeatureDiscussionBoardComponent, FeatureMembersComponent, FeaturePricingComponent]
})
export class FeaturesModule { }
