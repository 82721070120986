import { PeopleService } from './people.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayNameDirective } from './display-name.directive';
import { ProfileImageSrcDirective } from './profile-image-src.directive';
import { PersonDetailsComponent } from './person-details/person-details.component';
import { HideIfMeDirective } from './hide-if-me.directive';

@NgModule({
	imports: [
		CommonModule
	],
	exports: [
		DisplayNameDirective,
		ProfileImageSrcDirective,
		PersonDetailsComponent,
		HideIfMeDirective,
	],
	declarations: [DisplayNameDirective, ProfileImageSrcDirective, PersonDetailsComponent, HideIfMeDirective],
	providers: [PeopleService]
})
export class PeopleModule { }
