import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { NeighborhoodQuickViewComponent } from './neighborhood-quick-view/neighborhood-quick-view.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MembersComponent } from './members/members.component';
import { NeighborhoodRoutingModule } from "app/neighborhood/neighborhood-routing.module";
import { MemberAddComponent } from './member-add/member-add.component';
import { SitesService } from "app/core/services/sites.service";
import { MenusModule } from "app/menus/menus.module";
import { DisplayNameDirective } from './directives/display-name.directive';
import { MembersListComponent } from './members-list/members-list.component';
import { MemberCardComponent } from './member-card/member-card.component'; 
import { BsDropdownModule, TabsModule } from "ngx-bootstrap";
import { MemberViewComponent } from './member-view/member-view.component';
import { PeopleModule } from "app/people/people.module";
import { QuickListModule } from "app/quick-list/quick-list.module";
import { NeighborhoodEditComponent } from "app/neighborhood/neighborhood-edit/neighborhood-edit.component";
import { DocumentListComponent } from "app/neighborhood/neighborhood-quick-view/document-list/document-list.component";
import { NeighborhoodDetailEditComponent } from './neighborhood-detail-edit/neighborhood-detail-edit.component';
import { NeighborhoodBackgroundImageEditComponent } from './neighborhood-background-image-edit/neighborhood-background-image-edit.component';
import { NeighborhoodBackgroundImageAddComponent } from './neighborhood-background-image-add/neighborhood-background-image-add.component';
import { FilesModule } from "app/files/files.module";
import { NeighborhoodListComponent } from './neighborhood-list/neighborhood-list.component';
import { NeighborhoodMapEditComponent } from './neighborhood-map-edit/neighborhood-map-edit.component';
import { AgmCoreModule, GoogleMapsAPIWrapper } from "@agm/core";
import { NeighborhoodDocumentsEditComponent } from './neighborhood-documents-edit/neighborhood-documents-edit.component';
import { DragulaModule } from 'ng2-dragula/ng2-dragula';
import { NeighborhoodCreateComponent } from './neighborhood-create/neighborhood-create.component';
import { NeighborhoodSelectorComponent } from './neighborhood-selector/neighborhood-selector.component';
import { SubscriptionComponent } from 'app/neighborhood/subscription/subscription.component';
import { CheckoutComponent } from 'app/neighborhood/checkout/checkout.component';
import { GroupInviteListComponent } from 'app/neighborhood/group-invite-list/group-invite-list.component';
import { MomentModule } from 'angular2-moment';
import { TooltipModule } from 'ngx-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NeighborhoodRoutingModule,
		SharedModule,
		QuickListModule,
		MenusModule,
		PeopleModule,
		FilesModule,
		DragulaModule,
		BsDropdownModule.forRoot(),
		TabsModule.forRoot(),
		AgmCoreModule.forRoot(),
		MomentModule,
		ImageCropperModule,
		TooltipModule.forRoot()
	],
	declarations: [MembersComponent,
		MemberAddComponent,
		CheckoutComponent,
		DisplayNameDirective,
		MembersListComponent,
		MemberCardComponent,
		MemberViewComponent,
		NeighborhoodEditComponent,
		DocumentListComponent,
		GroupInviteListComponent,
		NeighborhoodQuickViewComponent,
		NeighborhoodDetailEditComponent,
		NeighborhoodBackgroundImageEditComponent,
		NeighborhoodBackgroundImageAddComponent,
		NeighborhoodMapEditComponent,
		NeighborhoodDocumentsEditComponent,
		NeighborhoodCreateComponent,
		NeighborhoodSelectorComponent,
		NeighborhoodListComponent,
		SubscriptionComponent],
	providers: [
		SitesService,
		GoogleMapsAPIWrapper
	],
	exports: [NeighborhoodQuickViewComponent, DisplayNameDirective,NeighborhoodSelectorComponent, NeighborhoodListComponent]
})
export class NeighborhoodModule { }
