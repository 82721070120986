import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutorial-messages',
  templateUrl: './tutorial-messages.component.html',
  styleUrls: ['./tutorial-messages.component.css']
})
export class TutorialMessagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}