import { Component, OnInit } from '@angular/core';
import { GroupsService } from "app/groups/groups.service";

@Component({
	selector: 'app-groups',
	templateUrl: './groups.component.html',
	styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {

	groups: any;

	constructor(private groupsService: GroupsService) { }

	ngOnInit() {
		this.groups = this.groupsService.getGroups();
	}

}
