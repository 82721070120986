import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lengthOfObject'
})
export class LengthOfObjectPipe implements PipeTransform {

  transform(value: any): any {
    return Object.keys(value).length;
  }

}
