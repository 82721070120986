import { Component, OnInit } from '@angular/core';
import { SitesService } from "app/core/services/sites.service";
import { AccountService } from "app/core/services/account.service";
import { AngularFireAuth } from 'angularfire2/auth';

@Component({
	selector: 'app-members-list',
	templateUrl: './members-list.component.html',
	styleUrls: ['./members-list.component.css']
})
export class MembersListComponent implements OnInit {

	memberKeys: any;
	memberKeyValues: any;
	nonMembers: any;
	query: string = '';
	
	groupKey: string;

	constructor(private sitesService: SitesService, 
				private accountService: AccountService,
				private afAuth: AngularFireAuth,) { }

	ngOnInit() {
		
		 
		var groupObj = {count:0};	
		var subscriptionObj = {status:''};

		var communityBoughtObj = {count: 0};
        var communityObj = {status:''};
        var communityGroupObj = {count: 0};
							
		let promises = Promise.all(
			[
				this.sitesService.getUserStatus2(this.afAuth.auth.currentUser.uid, subscriptionObj, groupObj),
				this.sitesService.getUserCommunityInfo2(this.afAuth.auth.currentUser.uid, communityObj, communityGroupObj, communityBoughtObj)
			]
		);

		promises.then(() => {
			
			if (groupObj.count > 0 || communityGroupObj.count > 0)  {
				this.accountService.currentSiteKey.subscribe(siteKey => {
					this.memberKeys = this.sitesService.getMemberKeys(siteKey);
					this.nonMembers = this.sitesService.getNonMembers(siteKey);
					this.groupKey = siteKey;
					//this.memberKeyValues = this.sitesService.getMemberKeyValues(siteKey);			
					});
			}  
				                                                            
		}, error => {					
			alert(error)
			
		});	

		
	}

}
