// author: hoan chau
// purpose: hold group/community information until the stripe returns with a success.
//          at that point, the database will get updated with information
//          

import { Injectable } from '@angular/core';
import { AngularFireDatabase } from "angularfire2/database";
import { AngularFireAuth } from "angularfire2/auth";
import * as firebase from 'firebase';
import { SitesService } from "app/core/services/sites.service";


@Injectable()
export class CheckOutService {
            
    constructor(private afAuth: AngularFireAuth,
                private siteService: SitesService) {}

    public groupImageFileDirect: File;   // used when the user doesn't have to go through subscription         


    setSubscription(value) {
        localStorage['Subscription'] = JSON.stringify(value);
    }

    getSubscription() {    
        return localStorage['Subscription'] ? JSON.parse(localStorage['Subscription']) : null;
    }
   
    setSessionID(value: string){
        localStorage['SessionID'] = JSON.stringify(value);
    }

    getSessionID(){
        return localStorage['SessionID'] ? JSON.parse(localStorage['SessionID']) : null;   
    }

    clearSessionID(){
        localStorage['SessionID'] = null;
    }  
    
    setSubscriptionsBought(value) {
        localStorage['SubscriptionsBought'] = JSON.stringify(value);
    }

    getSubscriptionsBought() {
        return localStorage['SubscriptionsBought'] ? JSON.parse(localStorage['SubscriptionsBought']) : null;   
    }

    getGroupCount() {
        return localStorage['GroupCount'] ? JSON.parse(localStorage['GroupCount']) : null;   
    }

    setGroupCount(value) {
        localStorage['GroupCount'] = JSON.stringify(value); 
    }

    setJoinGroupKey(value) {
        localStorage['JoinGroupKey'] = JSON.stringify(value);
    }

    getJoinGroupKey() {
        return localStorage['JoinGroupKey'] ? JSON.parse(localStorage['JoinGroupKey']) : null;
    }

    clearJoinGroupKey() {
        localStorage['JoinGroupKey'] = null;
    }

    setJoinGroupCreatedBy(value) {
        localStorage['JoinGroupCreatedBy'] = JSON.stringify(value);        
    }

    getJoinGroupCreatedBy() {
        return localStorage['JoinGroupCreatedBy'] ? JSON.parse(localStorage['JoinGroupCreatedBy']) : null;
    }

    clearJoinGroupCreatedBy() {
        localStorage['JoinGroupCreatedBy'] = null;
    }
}
