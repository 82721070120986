import { SitesService } from 'app/core/services/sites.service';
import { Injectable, EventEmitter } from '@angular/core';
import { AngularFireDatabase } from "angularfire2/database";
import { AngularFireAuth } from "angularfire2/auth";
import { NotificationsService } from "app/notifications/notifications.service";
import * as firebase from 'firebase';
import { AccountService } from "app/core/services/account.service";
import { BaseDiscussionService } from "app/core/services/base-discussion.service";
import { GroupsService } from "app/groups/groups.service";
import { Observable ,  BehaviorSubject } from "rxjs";

@Injectable()
export class DiscussionService extends BaseDiscussionService {

	private _discussionImagesRef: firebase.database.Reference;
	private messageSource = new BehaviorSubject('all');
	currentMessage = this.messageSource.asObservable();

	private msgCountSource = new BehaviorSubject(0);
	currentMsgCount = this.msgCountSource.asObservable();

	constructor(private _db: AngularFireDatabase, private _afAuth: AngularFireAuth,
		private _accountSerivce: AccountService,
		private _notificationsService: NotificationsService,
		private _memberGroupsService: GroupsService,
		private _sitesService: SitesService) {

		super('discussion-item', _db, _afAuth, _accountSerivce, _notificationsService, _memberGroupsService, _sitesService);

		this._discussionImagesRef = this._db.database.ref(`discussion-images`);
	}

	
	changeMessage(message: string) {
		this.messageSource.next(message)
	}

	changeMsgCount(count: number) {
		this.msgCountSource.next(count);
	}

	createPost(title: string, text: string, price: string, startDate:string, startTime:string, endDate:string, endTime:string, groupKey: string, category: string, tags: string[], albumKey: string, sendNotificationEmail: boolean) {

		return super.create(text, 'New discussion post added', groupKey, {
			title: title,
			category: category,
			price: price,
			startDate: startDate,
			startTime: startTime,
			endDate: endDate,
			endTime: endTime
		}, tags, albumKey, null, sendNotificationEmail);
	}	

	getAlbumImages() {
		return Observable.create(observer => {
			this._accountSerivce.currentSiteKey.subscribe(siteKey => {
				this._db.list(this._discussionImagesRef.child(siteKey),
					queryFN => queryFN.limitToLast(5)
				).valueChanges().subscribe(images => observer.next(images));
			});
		});
	}

	
}
