
import {mergeMap, map, take} from 'rxjs/operators';
import { AngularFireDatabase } from "angularfire2/database";
import { AngularFireAuth } from "angularfire2/auth";
import * as firebase from 'firebase';
import { Observable } from "rxjs";

export class BaseGroupService {

	protected _groupMembersRef: firebase.database.Reference;
	protected _groupNonMembersRef: firebase.database.Reference;
	protected _groups: firebase.database.Reference;
	protected _userGroups: firebase.database.Reference;
	protected _groupAdmins: firebase.database.Reference;

	constructor(private type: string, protected db: AngularFireDatabase, protected afAuth: AngularFireAuth) {
		this._groupMembersRef = this.db.database.ref(`${type}-members`);	
		this._groupNonMembersRef = this.db.database.ref(`site-non-members`);
		this._groups = this.db.database.ref(`${type}s`);
		this._userGroups = this.db.database.ref(`user-${type}s`);
		this._groupAdmins = this.db.database.ref(`${type}-administrators`);		
	}

	getMemberKeys(groupKey: string = null) {
		return this.db.list(this._groupMembersRef.child(groupKey))
			.snapshotChanges().pipe(
			map(ref => ref.map(ref1 => ref1.payload.key)));
	}

	getNonMembers(groupKey: string = null) {
		// return this.db.list(this._groupNonMembersRef.child(groupKey))
		// 	.snapshotChanges().pipe(
		// 	map(ref => ref.map(ref1 => ref1.payload.key)));

		return this.db.list(this._groupNonMembersRef.child(groupKey))
			.snapshotChanges().pipe(
				map(ref => ref.map(ref1 => ref1.payload.key)));
			
	}

	getNonMember(groupKey: string, email: string, userObj) {		
		var x;	
		let NonMemberInviteRefKey = encodeURIComponent(btoa(email.trim()));	

		return new Promise((resolve, reject) => {
			Promise.all([
				this._groupNonMembersRef.once("value")
		  .then(function(snapshot) {
			debugger;	
			  x = (snapshot.child(groupKey).child(NonMemberInviteRefKey)).val();
			  userObj.firstName = x.firstName;
			  userObj.lastName = x.lastName;			 			  								
			  return x;					
		  })
			]).then(() => resolve(true), error => reject(error));
		});
		
		
	}


	addMemberToPrivateSite(personKey: string, groupKey: string) {
		return new Promise((resolve, reject) => {
			Promise.all([
				this.db.object(this._groupMembersRef.child(groupKey).child(personKey)).set(false),
			]).then(() => resolve(true), error => reject(error));
		});
	}


	//getMemberKeyValues(groupKey: string = null) {
	//	return this.db.list(this._groupMembersRef.child(groupKey))
	//		.valueChanges()
	//		.map(ref => ref.map(ref1 => ref1));
	//}

	getMemberCount(groupKey: string) {
		return this.db.list(this._groupMembersRef.child(groupKey))
			.valueChanges().pipe(
			mergeMap((members: any) => members.length))
	}

	getAdminCount(groupKey: string, obj) {
		return this.db.list(this._groupAdmins.child(groupKey))
		.snapshotChanges().forEach(snapshot =>
		{
			snapshot.forEach(keys => {
				debugger;
				obj.count++;
			})
		});		
	}

	isMemberAdministrator(groupKey: string, uid: string): Observable<boolean> {
		return this.db.object(this._groupAdmins.child(groupKey).child(uid))
			.snapshotChanges().pipe(
			map(ref => {
				if (ref.payload.exists())
					return <boolean>ref.payload.val();
				else
					return false;
			}));
	}

	isAdministrator(groupKey: string) {
		return this.isMemberAdministrator(groupKey, this.afAuth.auth.currentUser.uid);
	}	

	getGroupKeys(parentGroupKey: string = null) {
		if (parentGroupKey === null)
			return this.db.list(this._userGroups.child(this.afAuth.auth.currentUser.uid))
				.snapshotChanges().pipe(
				map(refs => refs.map(ref => ref.payload.key)));
		else
			return this.db.list(this._userGroups.child(this.afAuth.auth.currentUser.uid)
				.child(parentGroupKey))
				.snapshotChanges().pipe(
				map(refs => refs.map(ref => ref.payload.key)));
	}

	protected getGroups(parentGroupKey: string = null) {
		return this.getGroupKeys(parentGroupKey).pipe(
			map(groupKeys => {
				return groupKeys.map(key => this.getGroup(key));
			}));
	}

	/*protected getGroupsByType() {
		return this.db.list(this._userGroups.child(this.afAuth.auth.currentUser.uid), {
			query: {
				orderByChild: 'groupType',
				equalTo: this.type
			}
		});
	}*/

	addMember(personKey: string, groupKey: string, isAdministrator: boolean = false) {
		
		return new Promise((resolve, reject) => {
			this.db.object(this._groups.child(groupKey))
				.snapshotChanges().pipe(
				take(1))
				.subscribe(group => {
					let userGroups = this._userGroups.child(personKey).child(groupKey);

					if ((<any>group.payload.val()).parentGroupKey) {
						userGroups = this._userGroups.child(personKey).child((<any>group.payload.val()).parentGroupKey).child(groupKey);
					}

					Promise.all([
						this.db.object(this._groupMembersRef.child(groupKey).child(personKey)).set(true),						
						this.db.object(userGroups).set(true),
						this.setAdministrator(personKey, groupKey, isAdministrator)
					]).then(() => resolve(true), error => reject(error));
				});
		});
	}

	addNonMember(siteKey: string, email: string, firstName: string, lastName: string )
	{
		let NonMemberInviteRefKey = encodeURIComponent(btoa(email.trim()));			
			
		this.db.database.ref(`site-non-members/${siteKey}/${NonMemberInviteRefKey}`).set({			
			createdBy: this.afAuth.auth.currentUser.uid,
			createdOn: firebase.database.ServerValue.TIMESTAMP,
			email:email,
			firstName: firstName,
			lastName: lastName,															
		});
		
	}

	setAdministrator(personKey: string, groupKey: string, isAdministrator: boolean = false) {
		return this.db.object(this._groupAdmins.child(groupKey).child(personKey)).set(isAdministrator);
	}

	getGroup(key: string) {
		return this.db.object(this._groups.child(key));
	}

	protected create(name: string, groupType: string, parentGroupKey: string = null) {		
		let ref = this.db.list(this._groups).push({
			name: name,
			groupType: groupType,
			parentGroupKey: parentGroupKey,
			createdBy: this.afAuth.auth.currentUser.uid,
			createdAt: firebase.database.ServerValue.TIMESTAMP,
			backgroundImage: {
				downloadURL: "assets/images/community-mockup.jpg"				
			},		
		});

		this.addMember(this.afAuth.auth.currentUser.uid, ref.key, true);

		return ref;
	}

	removeMember(personKey: string, groupKey: string) {
		return new Promise((resolve, reject) => {
			this.db.object(this._groups.child(groupKey))
				.snapshotChanges().pipe(
				take(1))
				.subscribe(group => {
					let userGroups = this._userGroups.child(personKey).child(groupKey);

					if ((<any>group.payload.val()).parentGroupKey) {
						userGroups = this._userGroups.child(personKey).child((<any>group.payload.val()).parentGroupKey).child(groupKey);
					}

					Promise.all([
						this.db.object(this._groupAdmins.child(groupKey).child(personKey)).remove(),
						this.db.object(this._groupMembersRef.child(groupKey).child(personKey)).remove(),
						this.db.object(userGroups).remove()
					]).then(() => resolve(true), error => reject(error));
				});
		});

		//this.db.object(this._groupAdmins.child(groupKey).child(personKey)).remove();

		//this.db.object(this._groupMembersRef.child(groupKey).child(personKey)).remove();
		//this.db.object(this._userGroups.child(groupKey).child(personKey)).remove();
	}

	removeNonMemberByKey(groupKey: string, key: string) {
		
		return new Promise((resolve, reject) => {
			this.db.object(this._groupNonMembersRef.child(groupKey))
				.snapshotChanges().pipe(
				take(1))
				.subscribe(group => {
					

					Promise.all([												
						this.db.object(this._groupNonMembersRef.child(groupKey).child(key)).remove()						
					]).then(() => resolve(true), error => reject(error));
				});
		});
	}

	removeNonMemberByEmail(groupKey: string, email: string) {
		let NonMemberInviteRefKey = encodeURIComponent(btoa(email.trim()));
		return new Promise((resolve, reject) => {
			this.db.object(this._groupNonMembersRef.child(groupKey))
				.snapshotChanges().pipe(
				take(1))
				.subscribe(group => {
					
					Promise.all([		
															
						this.db.object(this._groupNonMembersRef.child(groupKey).child(NonMemberInviteRefKey)).remove()						
					]).then(() => resolve(true), error => reject(error));
				});
		});
	}
}
