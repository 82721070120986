import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { PeopleService } from "app/people/people.service";
import { AccountService } from 'app/core/services/account.service';
import { SitesService } from 'app/core/services/sites.service';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
	selector: 'app-member-card',
	templateUrl: './member-card.component.html',
	styleUrls: ['./member-card.component.css'],
})

export class MemberCardComponent implements OnInit, OnChanges {

	@Input() key: string;
	@Input() size = '';
	@Input() groupKey: string;
	@Input() indexOfelement: number;
	@Input() searchFilter: string;
	@Input() nonMember: boolean;

	person: any;
	currentUserIsAdministrator: boolean = false;

	constructor(private peopleService: PeopleService,
				private accountService: AccountService,
				private sitesService: SitesService,
				private router: Router) { }

	ngOnInit() {
		this.accountService.isNeighborhoodAdministrator
				.subscribe(isAdmin => {
					this.currentUserIsAdministrator = isAdmin;
				});	
	}


	ngOnChanges() {
		
		if (!this.nonMember)
			this.person = this.peopleService.getPersonByKey(this.key).valueChanges();					
		else					
			this.person = this.peopleService.getNonMemberByKey(this.groupKey, this.key).valueChanges();				
										
					
		//console.log(this.person)
	}

	makeAdmin() {
		this.sitesService.setAdministrator(this.key, this.groupKey, true);	
	}
	makeNonAdmin() {
		this.sitesService.setAdministrator(this.key, this.groupKey, false);	
	}

	removeMember() {	

		var groupObj = {count:0};
		var subscriptionObj = {status:''};	

		var communityBoughtObj = {count: 0};
        var communityObj = {status:''};
        var communityGroupObj = {count: 0};

		if (!this.nonMember)
		{
			this.sitesService.removeMember(this.key, this.groupKey);			
			let promises = Promise.all(
				[
					this.sitesService.getUserStatus2(this.key, subscriptionObj, groupObj),
					this.sitesService.getUserCommunityInfo2(this.key, communityObj, communityGroupObj, communityBoughtObj)
				]
			);

			promises.then(() => {	
			
				groupObj.count--;
				if (groupObj.count < 0)
					groupObj.count = 0;
	
				this.sitesService.updateUserGroupStatus(this.key, groupObj);						
							
			}, error => {					
				alert(error)
			});
		}
		else
		{			
			this.sitesService.removeNonMemberByKey(this.groupKey, this.key);
		}
			
				
	}

	editMember() {
		this.router.navigate(['edit-profile'], { queryParams: { memberKey: this.key } });
	}
}
