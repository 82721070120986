import { Component, OnInit } from '@angular/core';
import { DiscussionService } from "app/discussion/discussion.service";

@Component({
	selector: 'app-discussion-recent-images',
	templateUrl: './discussion-recent-images.component.html',
	styleUrls: ['./discussion-recent-images.component.css']
})
export class DiscussionRecentImagesComponent implements OnInit {

	albums: any;

	constructor(private discussionService: DiscussionService) { }

	ngOnInit() {
		this.albums = this.discussionService.getAlbumImages();
	}

}
