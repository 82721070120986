import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { GroupsService } from "app/groups/groups.service";

@Component({
	selector: 'app-group-details',
	templateUrl: './group-details.component.html',
	styleUrls: ['./group-details.component.css']
})
export class GroupDetailsComponent implements OnInit, OnChanges {

	@Input() groupKey: string;

	group: any;

	constructor(private activatedRoute: ActivatedRoute, private groupsService: GroupsService) { }

	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			this.groupKey = params['id'];
			this.ngOnChanges();
		});
	}

	ngOnChanges() {
		this.group = this.groupsService.getGroup(this.groupKey);			
	}
}
