import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuickListViewComponent } from './quick-list-view/quick-list-view.component';
import { QuickListService } from "app/core/services/quick-list.service";
import { QuickListEditComponent } from './quick-list-edit/quick-list-edit.component';
import { SharedModule } from "app/shared/shared.module";
import { FormsModule } from "@angular/forms";
import { FilesModule } from "app/files/files.module";

@NgModule({
	imports: [
		FormsModule,
		CommonModule,
		SharedModule,
		FilesModule
	],
	exports: [QuickListViewComponent, QuickListEditComponent],
	declarations: [QuickListViewComponent, QuickListEditComponent],
	providers: [QuickListService]
})
export class QuickListModule { }
