import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentItemComponent } from './comment-item/comment-item.component';
import { CommentItemListComponent } from './comment-item-list/comment-item-list.component';
import { CommentItemCreateComponent } from './comment-item-create/comment-item-create.component';
import { FormsModule } from "@angular/forms";
import { CommentsService } from "app/comments/comments.service";
import { PeopleModule } from "app/people/people.module";
import { ReactionsModule } from "app/reactions/reactions.module";
import { BsDropdownModule } from 'ngx-bootstrap';
import { RouterModule } from "@angular/router";
import { SharedModule } from "app/shared/shared.module";
import { FilesModule } from './../files/files.module';

@NgModule({
	imports: [
		FormsModule,
		FilesModule,
		CommonModule,
		PeopleModule,
		RouterModule,
		ReactionsModule,
		SharedModule,
		BsDropdownModule.forRoot()
	],
	exports: [
        CommentItemListComponent,
        CommentItemCreateComponent
	],
	declarations: [CommentItemComponent, CommentItemListComponent, CommentItemCreateComponent],
	providers: [CommentsService]
})
export class CommentsModule { }
