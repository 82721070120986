import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { Injectable } from '@angular/core';

@Injectable()
export class PeopleService {

    private _peopleRef = this.db.database.ref(`people`);
    private _userStatusRef = this.db.database.ref(`user-status`);
    private _nonMemberRef = this.db.database.ref(`site-non-members`);

    constructor(private db: AngularFireDatabase, private afAuth: AngularFireAuth) { }

    getPersonByKey(key: string) {
        
        //console.log(this.db.object(this._peopleRef.child(key)))
        return this.db.object(this._peopleRef.child(key));
	}

    getNonMemberByKey(groupKey: string, key: string) {
        return this.db.object(this._nonMemberRef.child(groupKey).child(key));
    }
	
	isUserConnected(uid: string) {
		return this.db.object(this._userStatusRef.child(uid).child('connected')).valueChanges();
	}

}
