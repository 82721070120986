import { Component, OnInit, ViewChild } from '@angular/core';
import { DiscussionService } from "app/discussion/discussion.service";
import { SitesService } from "app/core/services/sites.service";
import { GroupSelectorComponent } from "app/groups/group-selector/group-selector.component";
import { map } from 'rxjs/operators';

@Component({
	selector: 'app-discussion-tags',
	templateUrl: './discussion-tags.component.html',
	styleUrls: ['./discussion-tags.component.css']
})
export class DiscussionTagsComponent implements OnInit {

	tags: any;
	discussions: any;
	discussionTypes = [0, 0, 0, 0, 0, 0];  // order is: discussion, classifieds, newsletter, lost & found, events, all

	discussionObj = {count: 0};
	memberCountObj = {count: 0};
	groupTypeObj = {status: '', name: ''};
    key;
	isGroup = false;
    
	
	constructor(private discussionService: DiscussionService, private sitesService: SitesService) {
		
	}

	getDiscussionCounts(a) {			
		// re-initialize
		for (var i = 0; i < 6; i++)
			this.discussionTypes[i] = 0;

		a.forEach(element => {			
			//console.log(element.$key.child('category').node_.value_);			
			if (element.$key.child('category').node_.value_ == 'discussion board')
				this.discussionTypes[0]++;
			else if (element.$key.child('category').node_.value_ == 'classified ads')			
				this.discussionTypes[1]++;							
			else if (element.$key.child('category').node_.value_ == 'newsletter')
				this.discussionTypes[2]++;
			else if (element.$key.child('category').node_.value_ == 'lost & found')
				this.discussionTypes[3]++;
			else if (element.$key.child('category').node_.value_ == 'events calendar')
				this.discussionTypes[4]++;							
		});
	}

	ngOnInit() {
		this.tags = this.discussionService.getTags();	
		this.discussionService.changeMessage('all');
		this.sitesService.currentMessageKey.subscribe(message => {this.selectNeighborhood(message);})
	}
	
	atob(key) {
		return atob(key);
	}	   

	clicked(category) {	
		//this.tagSelected.emit(tag)		
		this.discussionService.changeMessage(category);

		var iIndex = 0;
		if (category == 'discussion board')
			iIndex = 0;
		else if (category == 'classified ads')
			iIndex = 1;
		else if (category == 'newsletter')
			iIndex = 2;
		else if (category == 'lost & found')
			iIndex = 3;
		else if (category == 'events calendar')
			iIndex = 4;
		
		this.discussionService.changeMsgCount(this.discussionTypes[iIndex]);
	}

	selectNeighborhood(key) {		
		if (key != 'default key')
		{
			this.key = key;	
			let promises = Promise.all(
				[this.sitesService.getCurrentMemberCount(this.key, this.memberCountObj, this.groupTypeObj)]
			);
	
			promises.then((result) => {	
				if (this.groupTypeObj.status == 'small' || 
					this.groupTypeObj.status == 'medium' || 
					this.groupTypeObj.status == 'large')				
					this.isGroup = false;
				else
					this.isGroup = true;

				this.clicked('discussion board');
			});

			this.discussions = this.discussionService.getTopicCount(this.key)
					.snapshotChanges().pipe(map(snapshots => snapshots.map(snapshot => {
					return { $key: snapshot.payload }
					})));
							
			this.discussions.subscribe(a => {
				this.getDiscussionCounts(a);				
				this.discussionTypes[5] = a.length;					
			})
		}
        
    }
}
