import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Title } from '@angular/platform-browser';
import { AccountService } from "app/core/services/account.service";
import { SitesService } from 'app/core/services/sites.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-cameras-item',
    templateUrl: './cameras-item.component.html',
    styleUrls: ['./cameras-item.component.css']
})
export class CamerasItemComponent implements OnInit {

    site: any;
    siteKey: string;
    cameras: any;  // list of cameras 
    isAdmin = false; 
    isCreatedBy = false;
    cameraObj: any;  
    currentUser;
        
    constructor(private activatedRoute: ActivatedRoute, private titleService: Title,
        private sitesService: SitesService,
		private accountService: AccountService        
        ) {
        titleService.setTitle('Wissle Cameras');
        
    }
    
    ngOnInit() {    
        
        
        this.currentUser = this.accountService.currentUser.uid;

        this.accountService.currentSiteKey.pipe(take(1)).subscribe(siteKey => {
			this.sitesService.getGroup(siteKey)
				.valueChanges()
				.subscribe((site: any) => {                    
                    this.siteKey = siteKey;
					this.site = site;    
                    this.cameras = this.sitesService.getCameraKeysAndData(this.siteKey);                                    
				})
		});

        this.accountService.isNeighborhoodAdministrator
				.subscribe(isAdmin => {
					this.isAdmin = isAdmin;
				});	
        

    }

    

    removeCamera(ID: string)
    {
        console.log("CAMERA ID: " + ID);
        this.sitesService.removeCamera(this.siteKey, ID);        
    }
}
