import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal/modal.component';
import { ModalModule, BsDropdownModule } from 'ngx-bootstrap';
import { ViewImageCarouselDirective } from './view-image-carousel.directive';
import { RoutedModalComponent } from './routed-modal/routed-modal.component';
import { AddressComponent } from "app/shared/address/address.component";
import { FormsModule } from "@angular/forms";
import { GeocodeService } from "app/core/services/geocode.service";
import { AgmCoreModule } from "@agm/core";
import { LengthOfObjectPipe } from './pipes/length-of-object.pipe';
import { SearchDiscussion } from './pipes/search-discussion.pipe';
import { ObjectToArrayPipe } from "app/shared/pipes/object-to-array.pipe";
import { CurrentNeighborhoodDirective } from './directives/current-neighborhood.directive';
import { UserSignedInComponent } from './user-signed-in/user-signed-in.component';
import { GoogleplaceDirective } from './directives/google-place.directive';
import { AutofocusDirective } from './directives/auto-focus.directive';
import { AppPasswordDirective } from './directives/app-password.directive';
import { PhoneNumberFormatPipe } from "./pipes/phone-number-format";
import { SafePipe } from "./pipes/safe-url";
//import { ImagesModule } from "app/images/images.module";

@NgModule({
	imports: [
		CommonModule,
		ModalModule,
		FormsModule,
		BsDropdownModule.forRoot(),
		AgmCoreModule.forRoot()
	],
	providers: [GeocodeService],
	exports: [
		ModalComponent,
		RoutedModalComponent,
		AddressComponent,
		LengthOfObjectPipe,
		SearchDiscussion,
		ObjectToArrayPipe,
		UserSignedInComponent,
		GoogleplaceDirective,
		AutofocusDirective,
		AppPasswordDirective,
		PhoneNumberFormatPipe,
		SafePipe
	],
	declarations: [ModalComponent, AddressComponent, ViewImageCarouselDirective,
		LengthOfObjectPipe, SearchDiscussion,ObjectToArrayPipe, RoutedModalComponent, CurrentNeighborhoodDirective,
		UserSignedInComponent, GoogleplaceDirective, AutofocusDirective, AppPasswordDirective, PhoneNumberFormatPipe, SafePipe]
})
export class SharedModule { }
