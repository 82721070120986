import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Title } from '@angular/platform-browser';
import { AccountService } from "app/core/services/account.service";
import { SitesService } from 'app/core/services/sites.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-cameras',
    templateUrl: './cameras-fullscreen.component.html',
    styleUrls: ['./cameras-fullscreen.component.css']
})
export class CamerasFullscreenComponent implements OnInit {

    key: string;
	site: any;
	groups: any;
    title: string;

    constructor(private activatedRoute: ActivatedRoute, private titleService: Title,
        private sitesService: SitesService,
		private accountService: AccountService) {
        titleService.setTitle('Wissle Cameras');
     }

    ngOnInit() {
        this.activatedRoute.params
			.subscribe(params => {
				this.key = params.key;
            });
            this.accountService.currentSiteKey.pipe(take(1)).subscribe(siteKey => {
                this.sitesService.getGroup(siteKey)
                    .valueChanges()
                    .subscribe((site: any) => {
                        this.site = site;
                        this.title = site.name;
                    })
            });
    }

}
 