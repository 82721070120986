import { Directive, Input, OnChanges } from '@angular/core';
//import { ImagesService } from "app/images/images.service";

@Directive({
	selector: '[appViewImageCarousel]'
})
export class ViewImageCarouselDirective implements OnChanges {

	@Input('appViewImageCarousel') albumKey: string;

	constructor() { }

	ngOnChanges() {
		
	}
}
