import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ReactionsService } from "app/reactions/reactions.service";

@Component({
	selector: 'app-reactions',
	templateUrl: './reactions.component.html',
	styleUrls: ['./reactions.component.css']
})
export class ReactionsComponent implements OnInit, OnChanges {

	@Input() parentKey: string;

	liked = false;
	count = 0;
	
	constructor(private reactionsService: ReactionsService) { }

	ngOnInit() {
	}

	ngOnChanges() {
		this.reactionsService.count(this.parentKey).subscribe(count => this.count = count);
		this.reactionsService.reactedByYou(this.parentKey).subscribe(liked => this.liked = liked);
	}
	
	set() {
		if(this.liked) {
			this.reactionsService.remove(this.parentKey);
		} else {
			this.reactionsService.add(this.parentKey);
		}

		return false;
	}
}
