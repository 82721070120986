import { Component, OnInit, isDevMode } from '@angular/core';
import { AppEngineService } from "app/app-engine.service";
import { CheckOutService} from 'app/core/services/checkout.service';
import { Router } from '@angular/router';
import { SitesService } from "app/core/services/sites.service";
import { AngularFireAuth } from "angularfire2/auth";
import { Title } from '@angular/platform-browser';
import { AccountService } from "app/core/services/account.service";

@Component({
  selector: 'app-hoa',
  templateUrl: './hoa.component.html',
  styleUrls: ['./hoa.component.css']
})
export class HOAComponent implements OnInit {

  loggedin: boolean = false;

  communityBoughtObj = {count: 0};
	communityObj = {status:''};
  communityGroupObj = {count: 0};
  website = 'https://wissle.com';

  constructor(private appEngine: AppEngineService,
              private checkoutService: CheckOutService,
              private router: Router,
              private sitesService: SitesService,
              private afAuth: AngularFireAuth,
              private titleService: Title,
              private accountService: AccountService) {
                titleService.setTitle('Wissle HOA');
               }

  ngOnInit() {
    this.appEngine.auth.onAuthStateChanged((user) => {
			if (user) {        
        this.loggedin = true;
        this.sitesService.getUserCommunityInfo(this.afAuth.auth.currentUser.uid, this.communityObj, this.communityGroupObj, this.communityBoughtObj);			        
			} else {        
        this.loggedin = false;        
			}
      });
      
      if (isDevMode())
        this.website = 'https://wissle.com';
  }

  subscribeSmall() {		       
    if (!this.loggedin) {  // redirect to login          
      this.router.navigate(['/new-user'], { queryParams: { forwardUrl: '/neighborhood/create', communityType: 'Community', subscriptionType: 'small' }});
    }	
    else {
      if (this.communityGroupObj.count < this.communityBoughtObj.count){
        this.router.navigate(['/neighborhood/create']);
      }
      else {
        this.checkoutService.setSubscription('small');	
        this.checkoutService.setSubscriptionsBought(this.communityBoughtObj.count);		
        this.accountService.subscribeTo('price_1I49GBDZWXSMJjj8yOCqsufJ', this.website);        
      }
    }		
	}

	subscribeMedium() {	
    if (!this.loggedin) { // redirect to login    
      this.router.navigate(['/new-user'], { queryParams: { forwardUrl: '/neighborhood/create', communityType: 'Community', subscriptionType: 'medium' }});
    }	
    else {		
      if (this.communityGroupObj.count < this.communityBoughtObj.count) {
        this.router.navigate(['/neighborhood/create']);
      }
      else {
        this.checkoutService.setSubscription('medium');	
        this.checkoutService.setSubscriptionsBought(this.communityBoughtObj.count);		
        this.accountService.subscribeTo('price_1I49GkDZWXSMJjj8vzw2mjvt', this.website);        
      }
    }
	}

	subscribeLarge() {		
    if (!this.loggedin) { // redirect to login    
      this.router.navigate(['/new-user'], { queryParams: { forwardUrl: '/neighborhood/create', communityType: 'Community', subscriptionType: 'large' }});
    }	
    else {	
      if (this.communityGroupObj.count < this.communityBoughtObj.count) {
        this.router.navigate(['/neighborhood/create']);
      }
      else {
        this.checkoutService.setSubscription('large');	
        this.checkoutService.setSubscriptionsBought(this.communityBoughtObj.count);		
        this.accountService.subscribeTo('price_1I49HADZWXSMJjj8FDgAmSEl', this.website);        
      }
    }
	}

}
