
import {take} from 'rxjs/operators';
import { GroupsCreateComponent } from './../groups-create/groups-create.component';
import { ModalComponent } from 'app/shared/modal/modal.component';
import { SitesService } from 'app/core/services/sites.service';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { GroupsService } from "app/groups/groups.service";
import { AccountService } from "app/core/services/account.service";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-group-selector',
	templateUrl: './group-selector.component.html',
	styleUrls: ['./group-selector.component.css']
})
export class GroupSelectorComponent implements OnInit {

	@ViewChild('newGroup') newGroupModal: ModalComponent;

	@ViewChild(GroupsCreateComponent) groupCreateComponent: GroupsCreateComponent;

	@Input('canCreateGroup') canCreateGroup = true;
	isTest: boolean;

	selectedGroupKey = 'site';
	site: any;
	groups: any;
	title: string;



	constructor(private groupsService: GroupsService,
		private sitesService: SitesService,
		private accountService: AccountService,
		private route: ActivatedRoute) {
			this.isTest = this.route.snapshot.queryParams['isTest'] || false;
		 }

	ngOnInit() {
		this.groups = this.groupsService.getGroups();
		if (this.isTest) {			
			this.selectedGroupKey = 'private';
		}

		this.accountService.currentSiteKey.pipe(take(1)).subscribe(siteKey => {
			this.sitesService.getGroup(siteKey)
				.valueChanges()
				.subscribe((site: any) => {
					this.site = site;
					this.title = site.name;
					
					if (this.isTest){
						this.privateSelected();
					}
				})
		});

		if (this.groupCreateComponent !== undefined)
			this.groupCreateComponent.cancelled.subscribe(() => { this.newGroupModal.hide() });
			
		this.groupCreateComponent.created.subscribe(() => { this.newGroupModal.hide() });
	}

	groupSelected(group) {		
		this.selectedGroupKey = group.$key;
		this.title = group.name;
	}

	siteSelected() {		
		this.selectedGroupKey = 'site';
		this.title = this.site.name;						
	}

	privateSelected() {
		this.selectedGroupKey = 'private';
		this.title = 'Only me';
	}

}
