import { Component, OnInit } from '@angular/core';
import { SitesService } from "app/core/services/sites.service";

@Component({
    selector: 'app-document-list',
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.css']
})
export class DocumentListComponent implements OnInit {

    documents: any;

    constructor(private sitesService: SitesService) { }

    ngOnInit() {
        this.documents = this.sitesService.getDocumentList();
    }

}
