import { Component, OnInit, ViewChild, Output, EventEmitter, HostListener } from '@angular/core';
import { PostsService } from "app/posts/posts.service";
import { ModalComponent } from "app/shared/modal/modal.component";
import { AppEngineService, MessageTypes } from "app/app-engine.service";
import { PostItemComponent } from "app/posts/post-item/post-item.component";
import { CommentItemListComponent } from "app/comments/comment-item-list/comment-item-list.component";
import { SitesService } from 'app/core/services/sites.service';

@Component({
	selector: 'app-post-item-list',
	templateUrl: './post-item-list.component.html',
	styleUrls: ['./post-item-list.component.css']
})
export class PostItemListComponent implements OnInit {

	@ViewChild(ModalComponent) postModal: ModalComponent;
	@ViewChild('postItem') postItem: PostItemComponent;
	@ViewChild('comments') comments: CommentItemListComponent;

	posts: any;
	

	@Output() messageLatLng = new EventEmitter<any>();  // sent back to posts so that the map pin changes when scrolling

	constructor(public postsService: PostsService,
		        private sitesService: SitesService) { }

	locArray = [];
	lastTopCard = 0;
	topCard = 0;
	parentNumber = 0;

	receiveMessageVisible($event) {		
		this.messageLatLng.emit($event);
		this.sendLatLng($event.createdAt);
		
		// if (this.locArray.length > 0)
		// {		
		// 	debugger;	
		// 	if ($event.createdAt > this.locArray[0].createdAt) {// put at front		
		// 		debugger;						
		// 		this.locArray.unshift($event);
		// 	}				
		// 	else if ($event.createdAt < this.locArray[this.locArray.length - 1].createdAt) {// put at end	
		// 		debugger;		
		// 		this.locArray.push($event);
		// 	}				
		// 	else {
		// 		debugger;
		// 		var i = 0;
		// 		for (i = 0; i < this.locArray.length; i++)
		// 		{
		// 			if ($event.createdAt > this.locArray[i].createdAt)  // insert
		// 			{			
		// 				this.locArray.splice(i, 0, $event);
		// 				break;
		// 			}				
		// 		}
		// 	}						
		// }
		// else  // must be first one
		// 	this.locArray.push($event);
		
	}

	receiveMessageHover(locIndex) {	
	debugger;
		if (locIndex < 0)
		{			
			locIndex = 0;
			this.sendLatLng(locIndex);
		}			
		else if (locIndex >= this.locArray.length - 1)
		{
			this.sendLatLng(locIndex);
			locIndex = this.locArray.length - 1;
		}
		else
			this.sendLatLng(locIndex);
			
		this.messageLatLng.emit(this.locArray[locIndex]);
				
	}
	
	ngOnInit() {
		this.sitesService.currentMessageKey.subscribe(message => {this.clearLocations();})
	}

	loadMore() {
		this.postsService.loadMore();
	}

	clearLocations() {		
		this.locArray = [];
	}
//
	sendLatLng(locIndex) {
		var loc = {lat: 0.0, lng: 0.0};

		this.parentNumber = locIndex; // = Math.trunc(locIndex);

		//if (locIndex < 0)
		//	locIndex = 0;
		//else if (locIndex > this.locArray.length - 1)
		//	locIndex = this.locArray.length -1;

		//this.messageLatLng.emit(this.locArray[locIndex]);
	}	
	  
	/*@HostListener("window:scroll", ["$event"])
	onWindowScroll() {
		//In chrome and some browser scroll is given to body tag
		let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
		let max = document.documentElement.scrollHeight;
		// pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
		//console.log("You are scrolling pos: " + pos + " max:" + max);
		// take the current pos
		// subtract 71 which is the space above alert
		var x = max - pos - 71;
		// divide this by 150
		var y = x / 153;
		// the top one is the length of the array - the number of 
		var z = this.locArray.length - y;
		//console.log("The top card is: " + z);
		//;
		this.sendLatLng(z - 3);
		//if(pos == max )   {
			// Do your action here
		//}
	}*/
}
