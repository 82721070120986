import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickListService } from "app/core/services/quick-list.service";
import { UploaderComponent } from "app/files/uploader/uploader.component";

@Component({
	selector: 'app-quick-list-edit',
	templateUrl: './quick-list-edit.component.html',
	styleUrls: ['./quick-list-edit.component.css']
})
export class QuickListEditComponent implements OnInit {

	@ViewChild(UploaderComponent) uploader: UploaderComponent;

	@Input() title: string;
	@Input() parentKey: string;
	@Input() type: string;
	@Input() description: string;
	@Input() titlename: string;
	@Input() listdescription: string;

	itemTitle = '';
	itemDescription = '';
	items: any;

	constructor(private quickListService: QuickListService) { }

	ngOnInit() {
		this.items = this.quickListService.getList(this.parentKey, this.type);
	}

	add() {
		if (this.uploader.albumKey) {
			this.quickListService.add(this.parentKey,
				this.type,
				this.itemTitle,
				this.itemDescription,
				this.uploader.albumKey);

			this.itemDescription = '';
			this.itemTitle = '';
			this.uploader.clear();
		}
	}

}
