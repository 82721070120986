
import {mergeMap, map, take} from 'rxjs/operators';
import { AccountService } from 'app/core/services/account.service';
import { BaseGroupService } from 'app/core/services/base-group.service';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from "angularfire2/database";
import { AngularFireAuth } from "angularfire2/auth";
import { Md5 } from 'ts-md5/dist/md5';
import { Observable ,  BehaviorSubject } from "rxjs";
import * as firebase from 'firebase';
import * as users from './users';
import { HttpClient } from '@angular/common/http';
import { VariableAst } from '@angular/compiler';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Injectable()
export class SitesService extends BaseGroupService {

	private _sitesRef = this.db.database.ref(`sites`);
	private _siteInvitationsRef = this.db.database.ref('site-invitations')
	private _siteDocumentsRef = this.db.database.ref(`site-documents`);
	private _siteMapRef = this.db.database.ref(`site-map`);
	private _invitationRef = this.db.database.ref('invitations');
	private _userSitesRef = this.db.database.ref('user-sites');
	private _peopleRef = this.db.database.ref(`people`);
	private _userStatusRef = this.db.database.ref(`user-status`);
	private _siteMembersRef = this.db.database.ref('site-members');
	private _siteNonMembersRef = this.db.database.ref('site-non-members');
	private _siteCameraRef = this.db.database.ref('site-cameras');

	constructor(db: AngularFireDatabase, afAuth: AngularFireAuth,
		private accountService: AccountService,
		private http: HttpClient) {
		super('site', db, afAuth);
	}

	

	private messageKey = new BehaviorSubject('default key');
	currentMessageKey = this.messageKey.asObservable();


	addCamera(siteKey: string, cameraURL: string, cameraDesc: string)
	{		
		let cameraRecord = {
			
			createdBy: this.afAuth.auth.currentUser.uid,
			createdAt: firebase.database.ServerValue.TIMESTAMP,
			createdByName: this.afAuth.auth.currentUser.displayName ,
			url: cameraURL,
			desc: cameraDesc
		};

		let ref = this.db.list(`site-cameras/${siteKey}/`).push(cameraRecord);											
	}

	getCameraDetails(siteKey, key) {
		debugger;
		return this.db.object(this._siteCameraRef.child(siteKey).child(key));
	}	

	getCameras(siteKey: string)	{
		return this.db.list(this._siteCameraRef.child(siteKey))
		.valueChanges().pipe(
			map(ref => ref.map(ref1 => ref1)));
	}

	getCameraKeysAndData(siteKey: string)	{		
		return this.db.list(this._siteCameraRef.child(siteKey))
		.snapshotChanges().pipe(
			map(actions => actions.map(action =>  {
				const key = action.payload.key;
				const data = action.payload.val();
				return {key, data}
				})));
	}

	removeCamera(siteKey: string, ID: string) {
		return new Promise((resolve, reject) => {
			this.db.object(this._siteCameraRef.child(siteKey))
				.snapshotChanges().pipe(
				take(1))
				.subscribe(group => {
					

					Promise.all([												
						this.db.object(this._siteCameraRef.child(siteKey).child(ID)).remove()						
					]).then(() => resolve(true), error => reject(error));
				});
		});		
	}

	changeNeighborhood(key: string) {
		this.messageKey.next(key);	
	}	

	setCurrentNeighborhood(key: string) {		
		return this.accountService.setCurrentSite(key);
	}

	getCurrentNeighborhood() {
		return Observable.create(observer => {
			this.accountService.currentSiteKey.subscribe(siteKey => {
				this.db.object(this._sitesRef.child(siteKey))
					.snapshotChanges()
					.subscribe(
						site => observer.next(site.payload.val())
					);
			});
		});
	}

	getCurrentNeighborhoodKey() {
		return this.accountService.currentSiteKey;
	}

	getCurrentMemberCount(key, memberCountObj, groupTypeObj) {		
		var x;
		var y;
		var z;
		return this._sitesRef.once("value")
		  .then(function(snapshot) {	
			  x = memberCountObj.count = (snapshot.child(key).child("memberCount")).val();
			  y = groupTypeObj.status = (snapshot.child(key).child("groupType")).val();	
			  z = groupTypeObj.name = (snapshot.child(key).child("name")).val();				  								
			  return x;					
		  });	
	}

	updateMemberCount(key: string, memberCount: number) {		
		return this.db.object(this._sitesRef.child(key))
			.update({
				memberCount: memberCount										
			});
	}

	getPreviousInvite(email: string) {
		const emailHash = Md5.hashAsciiStr(email).toString();
		/*return this.getCurrentSiteKey().flatMap(siteRef => {
			return this.db.object(this._siteInvitationsRef.child(siteRef.$value).child(emailHash)).take(1);
		});*/
	}

	awaitingSignup() {
		/*return this.getCurrentSiteKey().flatMap(siteRef => {
			return this.db.list(this._siteInvitationsRef.child(siteRef.$value));
		});*/
	}

	getInvite(email: string, token: string, siteKey: string): Promise<any> {
		return new Promise((resolve, reject) => {
			const emailHash = Md5.hashAsciiStr(email).toString();
			this.db.object(this._siteInvitationsRef.child(siteKey).child(emailHash))
				.snapshotChanges().pipe(
				take(1)).subscribe(invite => {
					if (invite.payload.exists() && (<any>invite.payload.val()).email === email && (<any>invite.payload.val()).token === token) {
						resolve(invite);
					} else {
						reject('Error finding invite');
					}
				});
		});
	}

	sendInivite(email: string, firstName: string = null, lastName: string = null, address: string = null) {
		const emailHash = Md5.hashAsciiStr(email).toString();
		return this.accountService.currentSiteKey.pipe(mergeMap(key => {
			const token = Md5.hashAsciiStr(`${key}-${(new Date()).getTime()}`);
			return this.db.object(this._siteInvitationsRef.child(key).child(emailHash)).set({
				createdBy: this.afAuth.auth.currentUser.uid,
				createdOn: firebase.database.ServerValue.TIMESTAMP,
				email: email,
				firstName: firstName,
				lastName: lastName,
				address: address,
				name: `${firstName} ${lastName}`,
				completedSignup: false,
				token: token
			});
		}));
	}

	create(name: string, groupType: string) {
		let ref = super.create(name, groupType)
		this.accountService.setCurrentSite(ref.key);
		return ref;
	}

	removeDocument(key: string) {
		this.accountService.currentSiteKey.pipe(take(1)).subscribe(siteKey => {
			return this.db.object(this._siteDocumentsRef.child(siteKey).child(key)).remove();
		});
	}

	addDocument(order: number, document: File, title: string) {
		return this.accountService.currentSiteKey.pipe(take(1)).subscribe(siteKey => {
			let documentRef = this.db.list(this._siteDocumentsRef.child(siteKey)).push({});
			let storage = firebase.storage();
			let storageRef = storage.ref();
			let fileStorageRef = storageRef.child(documentRef.key).child(document.name);
			let fileUploadTask = fileStorageRef.put(document, { customMetadata: {}, cacheControl: 'max-age=31536000', contentType: document.type });

			return fileUploadTask.then(snapshot => {
				documentRef.update({
					filename: document.name,
					order: order,
					title: title,
					createdOn: firebase.database.ServerValue.TIMESTAMP,
					downloadURL: snapshot.downloadURL
				});
			});
		});
	}

	getDocumentList() {
		return Observable.create(observer => {
			this.accountService.currentSiteKey.subscribe(siteKey => {
				this.db.list(this._siteDocumentsRef.child(siteKey))
					.valueChanges()
					.subscribe(
						documents => observer.next(documents)
					);
			});
		});
	}

	sendEmailInvite(newUser: boolean, email: string, firstName?: string, lastName?: string,
		phoneNumber?: string, note?: string) {

		var thisNote = note;
		let invitationRefKey = encodeURIComponent(btoa(email + new Date()));
		let token = encodeURIComponent(btoa(JSON.stringify({
			email: email,
			invitationRefKey: invitationRefKey,
			firstName: firstName,
			lastName: lastName,
			phoneNumber: phoneNumber,			
			createdOn: (new Date()).getUTCDate()
		})));

		this.accountService.currentSiteKey.pipe(take(1)).subscribe(siteKey => {
			this.db.object(this._sitesRef.child(siteKey))
				.valueChanges().pipe(
				take(1))
				.subscribe((site: any) => {
					//console.log(invitationRefKey)
				
					this.db.object(this._invitationRef.child(invitationRefKey))
						.update({
							siteName: site.name,
							token: token,
							createdBy: this.afAuth.auth.currentUser.uid,
							email: email,
							siteKey: siteKey,
							phoneNumber: phoneNumber,
							firstName: firstName,
							lastName: lastName,
							note: thisNote,
							newUser: newUser,
							senderName: this.afAuth.auth.currentUser.displayName
						});
				});
		});
	}

	sendAlertInvite(key: string, email: string, firstName?: string, lastName?: string,
		phoneNumber?: string, note?: string){

		
			let SignupInvitationRefKey = encodeURIComponent(btoa(email.trim()));

			this.accountService.currentSiteKey.pipe(take(1)).subscribe(siteKey => {
				this.db.object(this._sitesRef.child(siteKey))
					.valueChanges().pipe(
					take(1))
					.subscribe((site: any) => {
						//console.log(invitationRefKey)
						var msg='You have been invited to join: ' + site.name;
						this.db.database.ref(`signup-invitation/${SignupInvitationRefKey}`).set({
							email:email,
							invited_sitename: site.name,
							invited_site_key:key,
							message:msg,
							createdBy:this.afAuth.auth.currentUser.uid,
							siteType: 'Group'
							
						});
					});
			});

			//this.sendInvitation(email, firstName, lastName, null, note);
			//this.sharedProvider.showAlert('Invitation have been sent');
			let invitationRefKey = encodeURIComponent(btoa(email.trim()));
			//console.log('site invitation');
			 this.db.database.ref(`site-invitation/${key}`).child(this.afAuth.auth.currentUser.uid).child(invitationRefKey)
				.set({
				people : false,
				email:email.trim()
			}); 
						
	}

	checkForEmailInvitation(email: string) {
		return this.db.object(this._invitationRef.child(encodeURIComponent(btoa(email))));
	}
	
	checkEmailExistence(email: string, emailExists){		
		this._peopleRef.orderByChild("email").equalTo(email).once("value",snapshot => {
			if (snapshot.exists()){
				var $this = this;	
				snapshot.forEach(function(childSnapshot) {										
					// Cancel enumeration
					emailExists.flag = true;
					emailExists.uid = childSnapshot.key;
					return true;
				});
			}
			else
			{
				emailExists.flag = false;
				return false;
			}
		});
	}

	checkMemberEmailExistence(siteKey: string, email: string, emailExists: any){

		emailExists.flag = false;
		let peopleRef = this._peopleRef;
		this._siteMembersRef.child(siteKey).once("value").then(function(snapshot) {
			if (snapshot.exists()) {												
				snapshot.forEach(function(childSnapshot) {						
					console.log("KEY", childSnapshot.key);								
					peopleRef.child(childSnapshot.key).orderByChild("email").once('value').then(function(snap) {			
						var localObj: any = snap.toJSON();						
						//console.log('EMAIL', localObj);
						if (localObj.email == email)
						{
							return emailExists.flag = true;						
						}																			
					})
				});
			}
			else
			{
				return emailExists.flag = false;
			}				
		})		
	}

	checkNonMemberEmailExistence(siteKey: string, email: string, emailExists: any){
		this._siteNonMembersRef.child(siteKey).orderByChild("email").equalTo(email).once("value",snapshot => {
			if (snapshot.exists()){
				var $this = this;					
				snapshot.forEach(function(childSnapshot) {										
					// Cancel enumeration
					emailExists.flag = true;
					emailExists.uid = childSnapshot.key;
					return true;
				});
			}
			else
			{
				emailExists.flag = false;
				return false;
			}
		});
	}

	checkEmailExistence2(email: string, emailExists){			
		return this._peopleRef.orderByChild("email").equalTo(email).once("value",snapshot => {
			if (snapshot.exists()){
				var $this = this;	
				snapshot.forEach(function(childSnapshot) {															
					emailExists.flag = true;
					emailExists.uid = childSnapshot.key;
					//console.log("email exists: ", emailExists.uid);
					return true;
				});
			}
			else
			{
				emailExists.flag = false;
				emailExists.uid = '';
				return false;
			}
		});
	}

	checkForEmailInvitation2(email: string, userObj) {
		this._invitationRef.orderByChild("email").equalTo(email).once("value",snapshot => {
			if (snapshot.exists()){
				var $this = this;	
				snapshot.forEach(function(childSnapshot) {										
					// Cancel enumeration
					userObj.uid = childSnapshot.key;
					//emailExists.flag = true;
					//emailExists.uid = childSnapshot.key;
					return true;
				});
			}
			else
			{
				//emailExists.flag = false;
				return false;
			}
		});
	}

	decodeSignupToken(token: string) {
		return JSON.parse(atob(token));
	}

	updateUserStatusDefault(uid: string) {
		this._userStatusRef.child(uid).child('status')
			.update({
				subscriptionName: 'essential',
				siteCount: 0,
			});
			
		this._userStatusRef.child(uid).child('group_status')
			.update({
				subscriptionName: 'essential',
				GroupCount: 0,
			});

		this._userStatusRef.child(uid).child('community_status')
			.update({
				subscriptionName: 'essential',
				GroupCount: 0,
				subscriptionsBought: 0
			});
	}

	updateUserGroupStatus(uid: string, groupObj)
	{					
		this._userStatusRef.child(uid).child('group_status')
		.update({		
			GroupCount: groupObj.count,						
		});		
	}

	updateUserCommunityStatus(uid: string, groupObj)
	{					
		this._userStatusRef.child(uid).child('community_status')
		.update({		
			GroupCount: groupObj.count,						
		});		
	}	

	updateUserStatusSubscription(uid: string, groupType: string, subscriptionName: string, subscriptionsBought: number)
	{			
		if (groupType == 'Group')
		{
			this._userStatusRef.child(uid).child('group_status')
			.update({				
				subscriptionName: subscriptionName
			});
		}	
		else
		{
			this._userStatusRef.child(uid).child('community_status')
			.update({
				subscriptionName: subscriptionName,
				subscriptionsBought: subscriptionsBought,			
			});
		}			
	}

	getUserStatus(uid: string, objStatus_, objGroupCount_) {				
		this._userStatusRef.once("value")
		  .then(function(snapshot) {					
			objStatus_.status = (snapshot.child(uid).child("group_status/subscriptionName")).val();
			objGroupCount_.count = (snapshot.child(uid).child("group_status/GroupCount")).val();
		  });			
	}

	getUserStatus2(uid: string, objStatus_, objGroupCount_) {				
		return this._userStatusRef.once("value")
		  .then(function(snapshot) {					
			objStatus_.status = (snapshot.child(uid).child("group_status/subscriptionName")).val();
			objGroupCount_.count = (snapshot.child(uid).child("group_status/GroupCount")).val();
		  });			
	}

	getUserCommunityInfo(uid: string, objStatus_, objGroupCount_, objBought_) {				
		this._userStatusRef.once("value")
		  .then(function(snapshot) {					
			objStatus_.status = (snapshot.child(uid).child("community_status/subscriptionName")).val();
			objGroupCount_.count = (snapshot.child(uid).child("community_status/GroupCount")).val();
			objBought_.count = (snapshot.child(uid).child("community_status/subscriptionsBought")).val();
		  });			
	}	

	getUserCommunityInfo2(uid: string, objStatus_, objGroupCount_, objBought_) {	
		
		return this._userStatusRef.once("value")
		  .then(function(snapshot) {								
			objStatus_.status = (snapshot.child(uid).child("community_status/subscriptionName")).val();
			objGroupCount_.count = (snapshot.child(uid).child("community_status/GroupCount")).val();
			objBought_.count = (snapshot.child(uid).child("community_status/subscriptionsBought")).val();	
		  });						
	}

	signUpNewUser(uid:string, firstName:string, lastName:string, email:string, address:string, phone:any)
	{
	
		//this.accountService.updateCellPhone(this.cellPhone);
		let numbers = [
			{ number: phone, type: "Cell", receiveSMS: true, receiveVoice: true }
		]
		this.updateUserStatusDefault(uid);
	
		return this.db.object(this._peopleRef.child(uid))
		.update({
		displayName: firstName + ' ' + lastName,
			//address:address,
			email:email,
			phoneNumbers:numbers,
			//isCompleted: true,
			//firstName
			// homeAddress
			// homeLocation
			//lastName
			firstName: firstName,
			lastName: lastName,
			phoneNumber: phone,
			
			profileImage:{
				downloadURL:"assets/images/default-profile.png",
				downloadURLOriginal:"assets/images/default-profile.png"
			}
			//siteId
		});
	}

	completeSignup(token: string, displayName: string, email: string, photoURL: string,
		phoneNumber?: string, address?: string) {
		
		this.updateUserStatusDefault(this.afAuth.auth.currentUser.uid);

		return this.db.object(this._invitationRef.child(token))
		.update({
			isCompleted: true,
			displayName: displayName ? displayName : email,
			photoURL: photoURL,
			email: email,
			uid: this.afAuth.auth.currentUser.uid,
			phoneNumbers: phoneNumber ? [{ number: phoneNumber, receiveSMS: false, receiveVoice: false, type: 'Cell' }] : null
		});			
	}

	signUpProfileUpdate(uid:string,firstName:string,email:string, address:string,phone:any, lastName:string){
		//console.log(firstName);
		//console.log(email);
		//console.log(address);
		//console.log(phone);
		//console.log(lastName);
	 
		let toUpdate= {
					displayName:firstName + ' ' + lastName,
					address:address,
					email:email,
					phoneNumbers:phone,
					//isCompleted: true,
					//firstName
					// homeAddress
					// homeLocation
					//lastName
					firstName:firstName,
					lastname: lastName,
					phoneNumber:phone,
					
					profileImage:{
						downloadURL:"assets/images/default-profile.png",
						downloadURLOriginal:"assets/images/default-profile.png"
					}
					//siteId
                };
				//console.log(toUpdate);
			this._peopleRef.child(uid).update(toUpdate);
	}

	getInvitationSiteKey(token) {		
		var x;		
		return this._invitationRef.once("value")
		  .then(function(snapshot) {	
			  x = (snapshot.child(token).child("siteKey")).val();			 			  								
			  return x;					
		  });
	}

	getSiteMap() {
		return Observable.create(observer => {
			this.accountService.currentSiteKey.subscribe(siteKey => {
				this.db.object(this._siteMapRef.child(siteKey))
					.valueChanges()
					.subscribe(siteMap => observer.next(siteMap));
			});
		});
	}


	// to ensure that the current site matches the updating site
	updateDetails(updatingsiteKey: string, name: string, description: string, typeGroup: string) {
		
		return this.accountService.currentSiteKey.subscribe(siteKey => {
			if (updatingsiteKey == siteKey)
			{
				return this.db.object(this._sitesRef.child(siteKey))
					.update({
						name: name,
						description: description,									
					});
			}
			else  // bogus
				return null;
		});
	}

	// to ensure that the current site matches the updating site
	updateImage2(updatingsiteKey: string, image: File) {		
		
		this.accountService.currentSiteKey.subscribe(siteKey => {	
			if (updatingsiteKey != siteKey)  // bogus
				return null;

			if (image == undefined || image == null)
				return;
			let storage = firebase.storage();
			let storageRef = storage.ref();
			let fileStorageRef = storageRef.child(siteKey).child(image.name);
			let fileUploadTask = fileStorageRef.put(image,
				{
					customMetadata:
					{
						updatePath: `sites/${siteKey}/backgroundImage`,
					}, cacheControl: 'max-age=31536000', contentType: image.type
				});

				let db = this.db;
				let siteRef = this._sitesRef;
				fileUploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
					//console.log('File available at', downloadURL);
					db.object(siteRef.child(siteKey).child('backgroundImage'))
						.update({
							//downloadURLOriginal: task.downloadURL,
							downloadURL: downloadURL
						});
				  });
		},error=>{
			//console.log(error)
		});
	}

	updateImage3(updatingsiteKey: string, image: File) {
	
		this.accountService.currentSiteKey.subscribe(siteKey => {	
			if (updatingsiteKey != siteKey)  // bogus
				return null;

			if (image == undefined || image == null)
				return;

			// Create a root reference
			var storageRef = firebase.storage().ref();

			// Create a reference to 'mountains.jpg'
			var mountainsRef = storageRef.child(siteKey).child(image.name);

			// Create a reference to 'images/mountains.jpg'
			var mountainImagesRef = storageRef.child(`sites/${siteKey}/backgroundImage/` + image.name);

			// While the file names are the same, the references point to different files
			mountainsRef.name === mountainImagesRef.name            // true
			mountainsRef.fullPath === mountainImagesRef.fullPath    // false	

			// Create file metadata including the content type
			var metadata = {
				customMetadata:
				{
					updatePath: `sites/${siteKey}/backgroundImage`,
				}, 
				cacheControl: 'max-age=31536000',
				contentType: image.type,
			};
  
			// Upload the file and metadata
			
			var uploadTask = storageRef.child(`sites/${siteKey}/backgroundImage/` + image.name).put(image, metadata);

			// Upload the file and metadata
			let db = this.db;
			let siteRef = this._sitesRef;
			uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
				//console.log('File available at', downloadURL);
				db.object(siteRef.child(siteKey).child('backgroundImage'))
					.update({
						//downloadURLOriginal: task.downloadURL,
						downloadURL: downloadURL
					});
			  });

			},error=>{
				//console.log(error)	
			});
		
	}

	updateImage4(updatingSiteKey: string, file: File)
	{
		this.accountService.currentSiteKey.subscribe(siteKey => {	
		
			if (updatingSiteKey != siteKey)  // bogus
					return null;

			if (file == undefined || file == null)
				return;

			// Create the file metadata
			var metadata = {
				customMetadata:
					{
						updatePath: `sites/${updatingSiteKey}/backgroundImage`,
					}, 
				//expires: '03-09-2491',
				//cacheControl: 'max-age=31536000',
				contentType: 'image/jpeg'
			};			

			var storageRef = firebase.storage().ref();
			// Upload file and metadata to the object 'images/mountains.jpg'
			var uploadTask = storageRef.child(updatingSiteKey).child(file.name).put(file, metadata);
		

			let db = this.db;
			let siteRef = this._sitesRef;

			
			
			// Listen for state changes, errors, and completion of the upload.
			uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
				function(snapshot) {
				// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
				var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				//console.log('Upload is ' + progress + '% done');
				switch (snapshot.state) {
					case firebase.storage.TaskState.PAUSED: // or 'paused'
					//console.log('Upload is paused');
					break;
					case firebase.storage.TaskState.RUNNING: // or 'running'
					//console.log('Upload is running');
					break;
				}
				}, function(error) {
			
				// A full list of error codes is available at
				// https://firebase.google.com/docs/storage/web/handle-errors
				switch (error.message) {
				case 'storage/unauthorized':
					// User doesn't have permission to access the object
					break;
			
				case 'storage/canceled':
					// User canceled the upload
					break;
						
				case 'storage/unknown':
					// Unknown error occurred, inspect error.serverResponse
					break;
				}
			}, function() {
				// Upload completed successfully, now we can get the download URL
				uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
				//console.log('File available at', downloadURL);
								
					db.object(siteRef.child(updatingSiteKey).child('backgroundImage'))
						.update({						
							downloadURL: downloadURL
						});
				
				});
			});

		},error=>{
			//console.log(error)	
		});
	}
	
	updateBackgroundImage(updatingSiteKey: string, file: File, data: string, reload: boolean) {
	
		if (data != null){
			//data = data.replace(`data:${file.type};base64,`, '');
			data = data.replace(`data:image/png;base64,`, '');
			let storage = firebase.storage();
			let storageRef = storage.ref();
			let siteRef = this._sitesRef;
			//var uploadTask = storageRef.child(updatingSiteKey).child(file.name).put(file, metadata);
			let fileStorageRef = storageRef.child(updatingSiteKey).child(file.name);
			let fileUploadTask = fileStorageRef.putString(data,
				'base64',
				{ customMetadata: { updatePath: `sites/${updatingSiteKey}/backgroundImage` }, cacheControl: 'max-age=31536000', contentType: file.type });
			return fileUploadTask
				.then(snapshot => {
					return snapshot.task.then(uploadTask => {
						return uploadTask.ref.getDownloadURL().then(downloadURL => {
							
							if (reload)
								window.location.reload();

							return this.db.object(siteRef.child(updatingSiteKey).child('backgroundImage'))
								.update({
									downloadURL: downloadURL,
									downloadURLOriginal: downloadURL
								});

								
						});
					});
				});
		}
		else {
			this.updateImage4(updatingSiteKey, file);
		}
		
	}

	updateImage(image: File) {
		this.accountService.currentSiteKey.subscribe(siteKey => {			
			let storage = firebase.storage();
			let storageRef = storage.ref();
			let fileStorageRef = storageRef.child(siteKey).child(image.name);
			let fileUploadTask = fileStorageRef.put(image,
				{
					customMetadata:
					{
						updatePath: `sites/${siteKey}/backgroundImage`,
					}, cacheControl: 'max-age=31536000', contentType: image.type
				});

			fileUploadTask.then(task => {

				this.db.object(this._sitesRef.child(siteKey).child('backgroundImage'))
					.update({
						//downloadURLOriginal: task.downloadURL,
						downloadURL: task.downloadURL,
					});
			});
		},error=>{
			//console.log(error)
		});
	}

	updateMap(paths: { lat: number, lng: number }[], zoom: number, latitude: number, longitude: number) {
		this.accountService.currentSiteKey.subscribe(siteKey => {
			this.updateMapByKey(siteKey, paths, zoom, latitude, longitude);
		});
	}

	updateMapByKey(siteKey: string, paths: { lat: number, lng: number }[], zoom: number, latitude: number, longitude: number) {
		this.db.object(this._siteMapRef.child(siteKey))
			.update({
				paths: paths,
				zoom: zoom,
				latitude: latitude,
				longitude: longitude,
				color: '#2196f3'
			});
	}

	getNeighorhoods() {
		return this.db.list(this._userSitesRef.child(this.afAuth.auth.currentUser.uid));
	}	
	
	getNeighborhoodCount(obj_) {		
		this.db.list(this._userSitesRef.child(this.accountService.currentUser.uid)).
		snapshotChanges().
		forEach(snapshot=> {
			snapshot.forEach(keys => {
			  obj_.count++;
			  //console.log("GROUP COUNT IS: ", obj_.count);
			})			
		});
	}

	addMemberToPrivateSite(key) {
		let ref = super.addMemberToPrivateSite(this.afAuth.auth.currentUser.uid, key);
		return ref;
	}

	getEmails(siteKey: string) {
		var obj;
		var peopleref = this._peopleRef;
		return this._siteMembersRef.child(siteKey).once('value').then(function(snapshot) {
			snapshot.forEach(keys => {
debugger;
				//obj = keys.toJSON();	
				console.log("OBJ KEY", keys.key);			
				// let found = false;
				// if (objArr !== undefined) {
				//     let x = 0;
				//     for (x = 0; x < objArr.length; x++) {
				//         if (objArr[x].createdBy === obj.createdBy) {
				//             found = true;
				//         }
				//     }	
				// }

				//if (!found) { // send to the previous commenters										
					//objArr.push(obj);												
				peopleref.child(keys.key).once('value').then(function(snap) {			
						var localObj = snap.toJSON();
						debugger;
						console.log('EMAIL', localObj);
						//if (localObj.email !== person.email)
							//return MessageService.sendEmail(subject, body, localObj.email);
						//else
						//    return null;
					})
				//}
				//else
				//    return null;
			})
		})
		
	}

	// setSubscriptionToBasic(customerId: string) {
		
	// 	var customerObj = {email: ''};
	// 	let promises = Promise.all(
    //         [
    //             this.accountService.getEmail(customerId, customerObj)
    //         ]
    //     );
	// 	var $this = this;
    //     promises.then(() => {            
    //         this._peopleRef.orderByChild("email").equalTo(customerObj.email).once("value",snapshot => {
	// 			if (snapshot.exists()){
	// 				debugger;
	// 				snapshot.forEach(function(childSnapshot) {										
	// 					// Cancel enumeration						
	// 					debugger;
	// 					$this.updateUserStatusSubscription(childSnapshot.key, 'Group', 'essential', 0);
	// 					$this.updateUserStatusSubscription(childSnapshot.key, 'Community', 'essential', 0);
	// 					return true;
	// 				});
	// 			}
	// 			else
	// 			{
	// 				return false;
	// 			}
	// 		});			                
            
    //     }, error => {					
    //         alert(error)            
    //     });	

		
		
	// }


			//
/*
	fixImage() {
		//console.log('start')
		this.db.list('people')
			.snapshotChanges()
			.subscribe(a => {
				a.forEach(b => {
					const data: any = b.payload.val();

					if (data.profileImage && data.profileImage.downloadURL == 'assets/images/default-profile.png') {
						//console.log(data.downloadURL)
						this.db.object(`people/${b.key}`)
							.update({
								'profileImage': {
									'downloadURL': 'https://www.wissle.com/assets/images/default-profile.png',
									'downloadURLOriginal': 'https://www.wissle.com/assets/images/default-profile.png'
								},
								'downloadURL': null,
								'downloadURLOriginal': null,
							}).then(() => //console.log('processing'));
					}
				})
			});
	}
*/
	/*processUsers() {
		users.users.map(user => {
			const account = {
				siteId: '-Krk14yi9mNg1MiCdSAe',
				displayName: user.accountDetail.displayName,
				email: user.accountDetail.email,
				firstName: user.accountDetail.firstName,
				homeAddress: {
					address: user.accountDetail.address,
					latitude: user.accountDetail.latitude,
					longitude: user.accountDetail.longitude,
				},
				interests: user.accountDetail.interests.map(i => i.text),
				lastName: user.accountDetail.lastName,
				notes: user.accountDetail.notes,
				phoneNumbers: user.accountDetail.phoneNumbers.map(p => {
					return {
						number: p.number, receiveSMS: true, receiveVoice: true, type: "Home phone"
					}
				}),
				profileImage: {
					downloadURL: 'assets/images/default-profile.png',
					downloadURLOriginal:'assets/images/default-profile.png'
				},
				pets: user.accountDetail.pets,
				work: {
					company: user.accountDetail.company,
					occupation: user.accountDetail.occupation
				},
				name: user.accountDetail.displayName,
				vehicles: user.accountDetail.vehicles
			}
			return this.http.post('https://us-central1-wie-1b0c6-p.cloudfunctions.net/module-usersEndpoint-addUser',
				account)
				.subscribe((user: any) => {
					//console.log(user)
					//this.addMember(user.uid, '-Krk14yi9mNg1MiCdSAe', false);
				})
		});
	}*/
}
/*
displayName: user.displayName,
	education: user.education,
	email: user.email,
	name: user.displayName,
	notes: user.notes,
	phoneNumbers: user.phoneNumbers || null,
	homeLocation: {
		address: user.address,
		latitude: user.latitude,
		longitude: user.longitude
	},
	profileImage: {
		downloadURL: 'assets/images/default-profile.png',
		downloadURLOriginal: 'assets/images/default-profile.png'
	},
	work: {
		company: user.company,
		occupation: user.occupation
	}

					*/