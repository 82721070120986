import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { Component, OnInit} from '@angular/core';
import { CheckOutService} from 'app/core/services/checkout.service';
import { AccountService } from './../../core/services/account.service';  
import { SitesService } from "app/core/services/sites.service";
import { NotificationsService } from './../../notifications/notifications.service';

@Component({
	selector: 'app-checkout',
	templateUrl: './Checkout.component.html',
	styleUrls: ['./Checkout.component.css']
})
export class CheckoutComponent implements OnInit {

	name = '';
	description = '';
	session_id = null;
	
	
	subscriptionCommunityObj = {status: ''};

	constructor(private afAuth: AngularFireAuth,
		private checkoutService: CheckOutService,
		private accountService: AccountService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private siteService: SitesService,
		private notificationsService: NotificationsService
		) { 
						
		}
		
	ngOnInit() {		
		this.activatedRoute.queryParams.subscribe(params => {        
			this.session_id = params['session_id'];  // session id needs to validates so thieves can't hijack a session
			try
			{								
				//console.log('session_id: ', this.session_id);
				if (this.session_id != null && this.session_id != undefined) {
									
					this.checkoutService.setSessionID(this.session_id);
					this.checkoutService.setSubscriptionsBought(this.checkoutService.getSubscriptionsBought());	
										
				}
			}
			catch (e)
			{				
				//console.log('session_id is bogus');
			}
		});	  			
					
	}
//
	ngAfterContentInit() {
		if (this.session_id != null && this.session_id != undefined)
		{
			this.session_id = null;
			var groupObj = {count: 0};
			var createdBy;
			var subscription;
			var typeCommunity;
			var communityBought = {count: 0};
			var communityObj = {status: ''};

			var groupKey = this.checkoutService.getJoinGroupKey();
			if (groupKey != null) {
				
				this.siteService.addMember(this.accountService.currentUser.uid, groupKey, false);
				createdBy = this.checkoutService.getJoinGroupCreatedBy();
				groupObj.count = this.checkoutService.getGroupCount();
				
				groupObj.count++;
				this.siteService.updateUserGroupStatus(this.accountService.currentUser.uid, groupObj);
				this.notificationsService.removeNotification(groupKey, createdBy);

				this.checkoutService.clearJoinGroupCreatedBy();
				this.checkoutService.clearJoinGroupKey();
				this.checkoutService.clearSessionID();

				// update user's subscription level
				subscription = this.checkoutService.getSubscription();												
				typeCommunity = 'Group';				
				this.siteService.updateUserStatusSubscription(this.afAuth.auth.currentUser.uid, typeCommunity, subscription, 0);								
				this.accountService.setCurrentSite(groupKey);
				this.router.navigate(['/neighborhood', 'members']);  // see the group they just joined
			}
			else
				this.router.navigate(['/neighborhood', 'create']);  // to complete the creation of the group/community
		}			
	}

	ntOnDestroy() {
		
	}
}
 