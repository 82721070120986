
import {mergeMap, take} from 'rxjs/operators';
import { NotificationsService } from 'app/notifications/notifications.service';
import { Injectable } from '@angular/core';
import { BaseGroupService } from "app/core/services/base-group.service";
import { AngularFireDatabase } from "angularfire2/database";
import { AngularFireAuth } from "angularfire2/auth";
import { AccountService } from "app/core/services/account.service";
import { Subscription ,  Observable } from "rxjs";

@Injectable()
export class GroupsService extends BaseGroupService {

    constructor(protected db: AngularFireDatabase,
        protected afAuth: AngularFireAuth,
        protected accountService: AccountService,
        protected notificationsService: NotificationsService) {
        super('group', db, afAuth);
    }

    createGroup(name: string, memberKeys?: string[]) {

        return this.accountService.currentSiteKey.pipe(take(1))
            .subscribe(key => {
                let ref = super.create(name, key);

                memberKeys.forEach(memberKey => {
                    super.addMember(memberKey, ref.key, false);
                });

                this.notificationsService.create(
                    `You have been added to the new group ${name}!`
                    , ref.key,
                    this.accountService.currentUser.photoURL, 
                    null, 
                    memberKeys,
                    null
                );

                return ref;
            });
    }

    getGroups() {
        return this.accountService.currentSiteKey.pipe(take(1))
            .pipe(mergeMap(key => super.getGroups(key)));
    }
}