import { ReplaySubject ,  BehaviorSubject } from 'rxjs';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import {loadStripe} from '@stripe/stripe-js';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';

@Injectable()
export class AccountService {

	private _applicationSecurityRef = this.db.database.ref(`application-security`);
	private _currentSiteRef = this.db.database.ref('user-current-site');
	private _siteAdministratorsRef = this.db.database.ref('site-administrators');
	private _peopleRef = this.db.database.ref('people');
	private _paymentRef = this.db.database.ref('payment-succeeded');
	private _paymentIntentRef = this.db.database.ref('payment-intent-succeeded');
	private _siteJoinRef = this.db.database.ref('site-join');
	private _sitesRef = this.db.database.ref('sites');

	hasCurrentSite = new ReplaySubject<boolean>(1);
	currentSiteKey = new ReplaySubject<string>(1);
	isAuthenticated = new ReplaySubject<boolean>(1);
	profileURL = new ReplaySubject<string>(1);
	displayName = new ReplaySubject<string>(1);
	currentSite = new ReplaySubject<string>(1);
	isSiteAdministrator = new BehaviorSubject<boolean>(false);
	isNeighborhoodAdministrator = new BehaviorSubject<boolean>(false);
	canAddSite = new BehaviorSubject<boolean>(false);
	user = new BehaviorSubject<firebase.User>(null);
	currentUser: firebase.User;
	activeSiteKey: string;
	profileCompleted = new ReplaySubject<boolean>(1);

	constructor(private db: AngularFireDatabase, 
		        private afAuth: AngularFireAuth,
				private afs : AngularFirestore) {

		this.afAuth.authState.subscribe(user => {
			this.currentUser = user;
			if (user) {
				this.user.next(user);
				this.isAuthenticated.next(true);
				this.db.object(this._currentSiteRef.child(user.uid))
					.snapshotChanges()
					.subscribe(site => {
						if (!site.payload.exists()) {
							this.hasCurrentSite.next(false);
						} else {
							this.hasCurrentSite.next(true);
							this.currentSiteKey.next(<string>site.payload.val());

							this.db.object(this._siteAdministratorsRef.child(<string>site.payload.val()).child(user.uid))
								.valueChanges()
								.subscribe(isAdministrator => {
									//isNeighborhoodAdministrator
									if (isAdministrator)
										this.isNeighborhoodAdministrator.next(<boolean>isAdministrator)
									else
										this.isNeighborhoodAdministrator.next(false);
								});
						}
					});

				this.db.object(this._peopleRef.child(user.uid).child('profile-completed'))
					.snapshotChanges()
					.subscribe(completed => {
						if (completed.payload.exists()) {
							this.profileCompleted.next(<boolean>completed.payload.val());
						} else {
							this.profileCompleted.next(false);
						}
					});

				this.db.object(this._applicationSecurityRef.child(user.uid))
					.snapshotChanges()
					.subscribe(security => {
						if (security.payload.exists()) {
							this.canAddSite.next((<any>security.payload.val()).canAddSite);
							this.isSiteAdministrator.next((<any>security.payload.val()).isAdministrator);
						}
					});
				this.db.object(this._peopleRef.child(user.uid).child('profileImage'))
					.valueChanges()
					.subscribe(profileImage => {
						if (profileImage != null)
							this.profileURL.next((<any>profileImage).downloadURL);
						else
							this.profileURL.next('assets/images/default-profile.png');
					});

				this.db.object(this._peopleRef.child(user.uid).child('displayName'))
					.valueChanges()
					.subscribe((displayName: string) => {
						this.displayName.next(displayName);
					});
			} else {
				this.user.next(null);
				this.isAuthenticated.next(false);
				this.displayName.next(null);
				this.profileURL.next(null);
			}
		});

		// TODO: Not a fan
		this.currentSiteKey.subscribe(siteKey => this.activeSiteKey = siteKey);
	}

	setProfileCompleted(uid: string, isComplete: boolean) {
		return this.db.object(this._peopleRef.child(uid).child('profile-completed')).set(true);
	}

	getUserDetails() {
		return this.db.object(this._peopleRef.child(this.afAuth.auth.currentUser.uid));
	}

	getMemberDetails(key) {
		return this.db.object(this._peopleRef.child(key));
	}

	//This functions needs to be deprecated
	updateAddress(address: string, latitude: number, longitude) {
		return this.db.object(this._peopleRef.child(this.afAuth.auth.currentUser.uid))
			.update({
				homeLocation: {
					address: address,
					latitude: latitude,
					longitude: longitude
				}
			});
	}

	//This functions needs to be deprecated
	updateCellPhone(number: string) {
		let numbers = [
			{ number: number, type: "Cell", receiveSMS: true, receiveVoice: true }
		]
		return this.db.object(this._peopleRef.child(this.afAuth.auth.currentUser.uid))
			.update({
				phoneNumbers: numbers
			});
	}

	changeCellNumberFormat(cell) {
		cell = cell.replace('(', '');
		cell = cell.replace(')', '');
		cell = cell.replace(' ', '-');		
		return cell;
	}

	updateDetails(displayName: string,
		firstName: string,
		lastName: string,
		occupation: string,
		company: string,
		phoneNumbers: any[],
		notes: string,
		education: string,
		pvtAddress: boolean,
		address: string,
		lat,
		lng,
		interests: any[]) {

		return this.db.object(this._peopleRef.child(this.afAuth.auth.currentUser.uid))
			.update({
				work: {
					company: company,
					occupation: occupation
				},
				displayName: displayName,
				firstName: firstName,
				lastName: lastName,
				phoneNumbers: phoneNumbers,
				notes: notes,
				education: education,
				pvtAddress: pvtAddress,
				address: address,
				homeLocation: {
					address: address,
					latitude: lat,
					longitude: lng
				},
				interests: interests
			});			
	}

	updateMemberDetails(key: string,
		displayName: string,
		firstName: string,
		lastName: string,
		occupation: string,
		company: string,
		phoneNumbers: any[],
		notes: string,
		education: string,
		pvtAddress: boolean,
		address: string,
		lat,
		lng,
		interests: any[]) {

		return this.db.object(this._peopleRef.child(key))
			.update({
				work: {
					company: company,
					occupation: occupation
				},
				displayName: displayName,
				firstName: firstName,
				lastName: lastName,
				phoneNumbers: phoneNumbers,
				notes: notes,
				education: education,
				pvtAddress: pvtAddress,
				address: address,
				homeLocation: {
					address: address,
					latitude: lat,
					longitude: lng
				},
				interests: interests
			});
			
	}

	setCurrentSite(key: string) {
		
		return this.db.object(this._currentSiteRef.child(this.afAuth.auth.currentUser.uid)).set(key);
	}

	getCurrentSite(obj) {		
		var data;		
		return this._currentSiteRef.child(this.afAuth.auth.currentUser.uid).once("value",snapshot => {
			if (snapshot.exists()){				
				data = JSON.stringify(snapshot);
				obj.key =JSON.parse(data);						
			}			
		});
	}

	getHomeAddress() {
		return this.db.object(this._peopleRef.child(this.afAuth.auth.currentUser.uid).child('homeLocation'));
	}

	getPayment(email, groupObj, communityObj) {
		var event;
		var eventParsed;

		return this._paymentRef.orderByChild("customer_email").equalTo(email).once("value", snapshot => {
			if (snapshot.exists()){
				var $this = this;	
				snapshot.forEach(function(childSnapshot) {															
					//console.log(childSnapshot.val());
					event = JSON.stringify(childSnapshot);
					eventParsed = JSON.parse(event);
					var dt = new Date(childSnapshot.val().lines.data[0].period.end * 1000).toLocaleDateString();
					//if (childSnapshot.val().lines.data[0].description.includes('Community')) {
					if (childSnapshot.val().lines.data[0].plan.id == 'price_1I49HRDZWXSMJjj8e2lZONBl' ||  // plus
						childSnapshot.val().lines.data[0].plan.id == 'price_1I49HLDZWXSMJjj8dfk3A5zH') {  // premium
						if (groupObj.invoice == '') {
							groupObj.end = dt;									
							groupObj.invoice = childSnapshot.val().id;
						}						
					}						
					else {
						
						if (communityObj.invoice == '') {
							debugger;
							communityObj.end = dt;
							communityObj.invoice = childSnapshot.val().id;	
						}											
					}						
				});		
				return true;		
			}
			else
			{
				communityObj = null;
				groupObj = null;
				return false;				
			}
		});
	}

	// getEmail2(customerId: string, customerObj) {
	// 	var event;
	// 	var eventParsed;
	// 	return this.db.database.ref('/payment-succeeded').orderByChild("customer").equalTo(customerId).once("value", snapshot => {
	// 		let that = this;
	// 		if (snapshot.exists()) {
	// 			console.log('CUSTOMER HERE');
	// 			snapshot.forEach(function(childSnapshot) {																				
	// 				debugger;
	// 				console.log('SNAPSHOT2 HERE');
	// 				event = JSON.stringify(childSnapshot);
	// 				eventParsed = JSON.parse(event);
	// 				const email = childSnapshot.val().customer_email;

	// 				console.log('CUSTOMER EMAIL:', email);
					
	// 				that.db.database.ref('/people').orderByChild('email').equalTo(email).once('value', person => {
	// 					let theother = that;
	// 					if (person.exists()) {
	// 						person.forEach(function(child) {
	// 							theother.db.database.ref('user-status').child(child.key).child('group_status')
	// 							.update({
	// 								subscriptionName: 'essential'
	// 							});

	// 							theother.db.database.ref('user-status').child(child.key).child('community_status')
	// 							.update({
	// 								subscriptionName: 'essential'
	// 							})
	// 						});

	// 						return true;
	// 					}
	// 					else
	// 						return false;
	// 				})				  
	// 				return false;					 				  									  
	// 			});
	// 			return false;		
	// 		}
	// 		else
	// 		{				
	// 			return true;				
	// 		}
	// 	});
	// }

	// getEmail(customerId: string, customerObj) {
	// 	var event;
	// 	var eventParsed;

	// 	return this._paymentRef.orderByChild("customer").equalTo(customerId).once("value",snapshot => {
	// 		if (snapshot.exists()){
	// 			debugger;
	// 			snapshot.forEach(function(childSnapshot) {															
	// 				//console.log(childSnapshot.val());
	// 				event = JSON.stringify(childSnapshot);
	// 				eventParsed = JSON.parse(event);
	// 				customerObj.email = childSnapshot.val().customer_email;
	// 				//this.siteService.updateUserStatusSubscription(this.afAuth.auth.currentUser.uid, typeCommunity, subscription, 0);
										
	// 				return;					
	// 			});		
	// 			return true;		
	// 		}
	// 		else
	// 		{				
	// 			return false;				
	// 		}
	// 	});
	// }

	getInvoice(invoiceID, obj) {
		
		this._paymentIntentRef.orderByChild("invoice").equalTo(invoiceID).once("value",snapshot => {
			if (snapshot.exists()){
				var $this = this;	
				snapshot.forEach(function(childSnapshot) {															
					obj.last4 = childSnapshot.val().charges.data[0].payment_method_details.card.last4;				
				});		
			}
			else {
				obj.last4 = null;	
			}
		});
	}

	getSiteJoinCode(siteKey: string, obj) {		
		var code = this.makeJoinCode(siteKey);	
		return this._siteJoinRef.orderByChild("code").equalTo(code).once("value",snapshot => {
			if (snapshot.exists()) {					
				snapshot.forEach(function(childSnapshot) {																				
					//debugger;
					var data = JSON.stringify(childSnapshot);
					var dataParsed = JSON.parse(data);
					for (var key in dataParsed) {
						var value = dataParsed[key];
						if (key == 'siteKey') 
							obj.siteKey = value;
						else if (key == 'code')
							obj.code = value;					
					 }					
				});		
				return true;		
			}
			else{				
				return false;				
			}
		});				
	}

	getSiteJoinKey(code: string, obj) {				
		return this._siteJoinRef.orderByChild("code").equalTo(code).once("value",snapshot => {
			if (snapshot.exists()) {					
				snapshot.forEach(function(childSnapshot) {																				
					//debugger;
					var data = JSON.stringify(childSnapshot);
					var dataParsed = JSON.parse(data);
					for (var key in dataParsed) {
						var value = dataParsed[key];
						if (key == 'siteKey') 
							obj.siteKey = value;
						else if (key == 'code')
							obj.code = value;					
					 }					
				});		
				return true;		
			}
			else{				
				return false;				
			}
		});				
	}

	getSiteName(siteKey: string, obj) {				
		return this._sitesRef.child(siteKey).once("value",snapshot => {
			if (snapshot.exists()){								
				var data = JSON.stringify(snapshot);
					var dataParsed = JSON.parse(data);
					for (var key in dataParsed) {
						var value = dataParsed[key];
						if (key == 'name') 
							obj.name = value;											
					 }					
			}			
		});

		
	}

	addJoinCode(siteKey: string) {
		var code = this.makeJoinCode(siteKey);		
		this.db.list(this._siteJoinRef).push({
			code: code,
			siteKey: siteKey					
		});		
	}

	makeJoinCode(siteKey: string) {
		siteKey = siteKey.replace(new RegExp("-", "g"), 'D');
		siteKey = siteKey.replace(new RegExp("_", "g"), 'U');
		siteKey = siteKey.toUpperCase();
		if (siteKey.length > 6) {
			siteKey = siteKey.substr(siteKey.length - 6, 6);			
		}
		else {
			alert('Could not generate code from siteKey ' + siteKey + ' because it is not long enough.');
		}
		return siteKey;
	}

	checkJoinCode(siteKey: string, obj) {
		var code = this.makeJoinCode(siteKey);
		return this._siteJoinRef
				.child(code)
				.once('value')
				.then(dataSnapshot => {
					if (dataSnapshot.exists)
						obj.found = 'true';
					else
						obj.found = 'false';
					return Promise.resolve({						
						codeExists: dataSnapshot.exists(),
					});
				});		
	}

	async showPortal() {	
	  debugger;
		
		const functionRef = firebase
		.app()
		.functions('us-central1')
		.httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
	  const { data } = await functionRef({ returnUrl: 'https://www.wissle.com/edit-profile' });
	  debugger;
	  window.location.assign(data.url);  
	}

	async subscribeTo(price: string, website: string) {
		var docRef = await this.afs
        .collection('customers')
        .doc(this.currentUser.uid)
        .collection('checkout_sessions')
        .add({
          mode: "subscription",
          payment_method_types: ["card"],
          line_items: [
            {
            price: price,
            // For metered billing, do not pass quantity
            quantity: 1,
            },
          ],

          // {CHECKOUT_SESSION_ID} is a string literal; do not change it!
          // the actual Session ID is returned in the query parameter when your customer
          // is redirected to the success page.			
          success_url: website + '/neighborhood/checkout?session_id={CHECKOUT_SESSION_ID}',
          cancel_url: website + '/neighborhood/create',
        });				
        
        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot(async (snap) => {
          const { error, sessionId } = snap.data();
          debugger;
          if (error) {
            // Show an error to your customer and 
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
          }

          if (sessionId) {
            // We have a session, let's redirect to Checkout
            
            stripe.redirectToCheckout({
              sessionId: sessionId}).then(function (result) {
              if (result.error) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer.
                var displayError = document.getElementById('error-message');
                displayError.textContent = result.error.message;
              }
            });
                  
          }
        }); 
	}


	setHomeAddress(address: string, latitude: number, longitude: number) {
		return this.db.object(this._peopleRef.child(this.afAuth.auth.currentUser.uid).child('homeLocation'))
			.update({
				address: address,
				latitude: latitude,
				longitude: longitude
			});
	}

	updatePassword(email: string, password: string, newPassword: string) {
		return this.afAuth.auth.signInWithEmailAndPassword(email, password)
			.then(() => this.afAuth.auth.currentUser.updatePassword(newPassword));
	}

	updateEmail(email: string, password: string, newEmail: string) {
		return this.afAuth.auth.signInWithEmailAndPassword(email, password)
			.then(() => this.afAuth.auth.currentUser.updateEmail(newEmail))
			.then(() => {
				this.db.object(this._peopleRef.child(this.afAuth.auth.currentUser.uid))
					.update({
						email: newEmail
					});
			});
	}

	updateProfileImage(file: File, data: string) {
		data = data.replace(`data:image/png;base64,`, '');
		let storage = firebase.storage();
		let storageRef = storage.ref();
		let fileStorageRef = storageRef.child('profile-images').child(this.currentUser.uid);
		let fileUploadTask = fileStorageRef.putString(data,
			'base64',
			{ customMetadata: { updatePath: `people/${this.currentUser.uid}/profileImage` }, cacheControl: 'max-age=31536000', contentType: file.type });
		return fileUploadTask
			.then(snapshot => {
				return snapshot.task.then(uploadTask => {
					return uploadTask.ref.getDownloadURL().then(downloadURL => {
						return this.db.object(this._peopleRef.child(this.currentUser.uid).child('profileImage'))
							.update({
								downloadURL: downloadURL,
								downloadURLOriginal: downloadURL
							});
					});
				});
			});
	}

	signout() {
		this.isAuthenticated.next(false);
		return this.afAuth.auth.signOut();
	}	
}
