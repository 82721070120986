import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Title } from '@angular/platform-browser';
import { DiscussionService } from 'app/discussion/discussion.service';

@Component({
	selector: 'app-discussion',
	templateUrl: './discussion.component.html',
	styleUrls: ['./discussion.component.css']
})
export class DiscussionComponent implements OnInit {

	key: string;

	constructor(private activatedRoute: ActivatedRoute,
		        private titleService: Title,
		        private discussionService: DiscussionService) {
		titleService.setTitle('Wissle Community'); }

	ngOnInit() {
		this.activatedRoute.params
			.subscribe(params => {
				this.key = params.key;
			});

		if (this.discussionService.getFeed().length == 0) {
			//this.discussionService.getPastDiscussions();
		}
	}

}
