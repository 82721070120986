import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupsService } from "app/groups/groups.service";
import { GroupListComponent } from './group-list/group-list.component';
import { GroupsComponent } from './groups/groups.component';
import { GroupsCreateComponent } from './groups-create/groups-create.component';
import { FormsModule } from "@angular/forms";
import { GroupsMemberAddComponent } from './groups-member-add/groups-member-add.component';
import { RouterModule } from "@angular/router";
import { GroupDetailsComponent } from './group-details/group-details.component';
import { GroupsMemberListComponent } from './groups-member-list/groups-member-list.component';
import { PeopleModule } from "app/people/people.module";
import { BsDropdownModule } from "ngx-bootstrap";
import { GroupSelectorComponent } from './group-selector/group-selector.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
        PeopleModule,
        SharedModule,
		BsDropdownModule.forRoot()
	],
	declarations: [GroupListComponent, GroupsComponent, GroupsCreateComponent, GroupsMemberAddComponent, GroupDetailsComponent, GroupsMemberListComponent, GroupSelectorComponent],
	providers: [GroupsService],
	exports: [GroupsComponent, GroupDetailsComponent, GroupSelectorComponent]
})
export class GroupsModule { }
