import { FeatureAlertsComponent } from './feature-alerts/feature-alerts.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeaturesComponent } from "app/marketing/features/features.component";
import { FeaturePricingComponent } from "app/marketing/features/feature-pricing/feature-pricing.component";
import { FeatureMembersComponent } from "app/marketing/features/feature-members/feature-members.component";
import { FeatureDiscussionBoardComponent } from "app/marketing/features/feature-discussion-board/feature-discussion-board.component";

const routes: Routes = [
    {
        path: 'marketing/features', component: FeaturesComponent,
        children: [
            { path: 'alerts', component: FeatureAlertsComponent },
            { path: 'discussion-board', component: FeatureDiscussionBoardComponent },
            { path: 'members', component: FeatureMembersComponent },
            { path: 'pricing', component: FeaturePricingComponent },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FeaturesRoutingModule { }
