import { ModalComponent } from 'app/shared/modal/modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { AccountService } from '../../core/services/account.service';
import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { SitesService } from 'app/core/services/sites.service';
import * as firebase from 'firebase';
import { PeopleService } from 'app/people/people.service';
//import { MapsAPILoader } from '@agm/core';
import { AngularFireDatabase } from "angularfire2/database";
import { NotificationsService } from 'app/notifications/notifications.service';
import { Title } from '@angular/platform-browser';
import { ImageCroppedEvent } from 'ngx-image-cropper';
declare var google: any;


@Component({
	selector: 'app-signup',
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

	@ViewChild(ModalComponent) waitingModal: ModalComponent;

	private geocoder: any;

	data: any;
	file: any;

	code= '';
	signupToken = '';
	invitationRefKey = '';
	element: HTMLInputElement;
	joinSiteCode: string = '';
	joinObj = { found: '', siteKey: '', code: '' };
	siteObj = { name: '' }


	constructor(
		//private _mapsAPILoader: MapsAPILoader,
		private _ngZone: NgZone,
		private router: Router,
		private accountService: AccountService,
		private sitesService: SitesService,
		private afAuth: AngularFireAuth,
		public peopleService: PeopleService,
		private activatedRoute: ActivatedRoute,
		private afDatabase: AngularFireDatabase,
		private notificationsService: NotificationsService,
		private titleService: Title,
		private db: AngularFireDatabase) {
		titleService.setTitle('Wissle Sign Up');

		this.data = {};
	}

	ngOnInit() {
	
		//console.log('URL', this.neighborhoodCreateUrl);
		//console.log('community ', this.communityType);

		if (this.accountService.currentUser) {

			//console.log()
			this.accountService.signout();
			window.location.reload();
		}

		


		this.invitationRefKey = 'New Signup';

	}

	


	submit() {

		if (!this.code) {
			// show error message for missing fields
			return;
		  }
		  let promises = Promise.all([
			this.accountService.getSiteJoinKey(this.code, this.joinObj)
		  ]);
		
		  promises.then(() => {
			if (this.joinObj.siteKey != '') {
			  window.location.href = 'https://wissle.com/join/' + this.code;
			} else {
			  alert('The provided code does not exist in our system.');
			}
		  }, error => {
			alert(error);
		  });
		}
	
}
