import { MarketingModule } from './marketing/marketing.module';
import { FilesModule } from './files/files.module';
import { CoreModule } from './core/core.module';
import { MenusModule } from './menus/menus.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { AppComponent } from './app.component';
import { PostsModule } from "app/posts/posts.module";
import { SharedModule } from "app/shared/shared.module";
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AppEngineService } from "app/app-engine.service";
import { ROUTES } from './app.routes';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { PagesModule } from "app/pages/pages.module";
import { AuthGuard } from "app/app.guard";
import { AccountModule } from "app/account/account.module";
import { AgmCoreModule } from '@agm/core';
import { DiscussionModule } from "app/discussion/discussion.module";
import { CamerasModule } from "app/cameras/camears-module"
import { NotificationsModule } from "app/notifications/notifications.module";
import { PeopleModule } from "app/people/people.module";
import { GroupsModule } from "app/groups/groups.module";
import { NeighborhoodModule } from "app/neighborhood/neighborhood.module";
import { AngularFirestore } from 'angularfire2/firestore';


export const firebaseConfig = {
	apiKey: "AIzaSyDYnimbTLO-Mib1wL8i_HuWtroKuLcOkFs",
    authDomain: "wie-1b0c6-p.firebaseapp.com",
    databaseURL: "https://wie-1b0c6-p.firebaseio.com",
    projectId: "wie-1b0c6-p",
    storageBucket: "wie-1b0c6-p.appspot.com",
	appId: "1:728681816189:web:58ebddcfeefa771e6a5114",
	measurementId: "G-254440252"
}


@NgModule({
	declarations: [
		AppComponent,
	
	],
	imports: [
		RouterModule.forRoot(ROUTES, { useHash: false, preloadingStrategy: PreloadAllModules }),
		BrowserModule,
		FormsModule,
		HttpModule,
		HttpClientModule,
		PostsModule,
		SharedModule,
		PagesModule,
        AccountModule,
		MenusModule,
		DiscussionModule,
		CamerasModule,
		NotificationsModule,
		GroupsModule,
		PeopleModule,
        FilesModule,
		MarketingModule,
		NeighborhoodModule,
		AngularFireModule.initializeApp(firebaseConfig),
		AngularFireDatabaseModule,
		AngularFireAuthModule,
		AgmCoreModule.forRoot({
            apiKey: "AIzaSyDYnimbTLO-Mib1wL8i_HuWtroKuLcOkFs"
			//libraries: ["places"]
        }),
        CoreModule
	],
	providers: [AppEngineService, AuthGuard, AngularFirestore],
	bootstrap: [AppComponent]
})
export class AppModule { }
