import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountService } from "app/core/services/account.service";
import { ModalComponent } from 'app/shared/modal/modal.component';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SitesService } from "app/core/services/sites.service";

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.css']
})
export class MembersComponent implements OnInit {

  //@ViewChild(ModalComponent) welcomeModal: ModalComponent;
  @ViewChild('welcomeModal') welcomeModal: ModalComponent;

  neighborhoodWelcome;
  siteName;
  siteObj = {key: 'default key'};

  constructor(private titleService: Title,
              private activatedRoute: ActivatedRoute,
              private accountService: AccountService,
              private sitesService: SitesService) {
    this.neighborhoodWelcome = this.activatedRoute.snapshot.queryParams['welcome'] || '/';
        
    titleService.setTitle('Wissle Members'); }

  ngOnInit() {        
        this.siteName = this.accountService.getCurrentSite(this.siteName);

        let promises = Promise.all(
          [
            this.accountService.getCurrentSite(this.siteObj)
          ]
        );

        promises.then(() => {					
          this.sitesService.getGroup(this.siteObj.key)
					.valueChanges()
					.subscribe((site: any) => {												
						this.siteName = site.name;
					});						
        }, error => {					
          alert(error)
        });	

       
  }

  ngAfterViewInit() {
    if (this.neighborhoodWelcome == "1")
      this.welcomeModal.show();
  }

}
