import { Directive, Input, OnChanges, ElementRef } from '@angular/core';
import { AngularFireAuth } from "angularfire2/auth";

@Directive({
	selector: '[hideIfMe]'
})
export class HideIfMeDirective implements OnChanges {

	@Input('hideIfMe') uid: string;

	constructor(protected afAuth: AngularFireAuth, private elementRef: ElementRef) { }

	ngOnChanges() {
		if(this.afAuth.auth.currentUser.uid === this.uid) {
			this.elementRef.nativeElement.parentNode.removeChild(this.elementRef.nativeElement);
		}
	}
}
