import { Component, OnInit } from '@angular/core';
import { SitesService } from "app/core/services/sites.service";
import { AccountService } from "app/core/services/account.service";
import { CheckOutService } from 'app/core/services/checkout.service';
import { NotificationsService } from './../../notifications/notifications.service';
import { Router} from "@angular/router";
import { filter, pairwise } from 'rxjs/operators';
import { map } from 'rxjs/operators';

@Component({
	selector: 'app-neighborhood-list',
	templateUrl: './neighborhood-list.component.html',
	styleUrls: ['./neighborhood-list.component.css']
})
export class NeighborhoodListComponent implements OnInit {

	neighborhoodsDetails = [];
	site: any;
	siteKey: any;
	currentSite: any;
	neighborhoods: any;
	isFirstKey: boolean = false;  // used to notify the siblings to use the first group in the list
	backgroundImageGroup;
	backgroundImageCommunity;

	isAuthenticated = false;
	hasNewNotifications: any;
	unreadNotificationCount: any;
	notifications: any;

	previousURL: string;

	siteObj = {key: 'default key'};
	

	constructor(private sitesService: SitesService,
		        private accountService: AccountService,
		        private notificationsService: NotificationsService,	
		        private checkoutService: CheckOutService,
		        private router: Router) {
					
		this.neighborhoods = this.sitesService.getNeighorhoods()
		.snapshotChanges().pipe(map(snapshots => snapshots.map(snapshot => {
			return { $key: snapshot.key }
		})));
	}

	getNeighborhoodsDetails(a) {
		a.forEach(element => {
			//
			if (element.$key) {

				this.sitesService.getGroup(element.$key)
					.valueChanges()
					.subscribe((site: any) => {

						//console.log("name", site.name)
						if (site && site.name) {
							var noImage = false;
							if (site.backgroundImage.downloadURL == 'assets/images/community-mockup.jpg')
								noImage = true;

							var isGroup = false;
							if (site.groupType == 'essential' || site.groupType == 'plus' || site.groupType == 'premium')
								isGroup = true;
							this.neighborhoodsDetails.push({ "typeGroup": site.typeGroup, "address": site.address, "noImage": noImage, "isGroup": isGroup, "backgroundImage": site.backgroundImage.downloadURL, "createdBy": site.createdBy, "description": site.description, "groupType": site.groupType, "memberCount": site.memberCount, "name": site.name, "siteType": site.siteType, "siteKey": element.$key });
							//this.neighborhoodsDetails.push({"address": site.address,  "createdBy": site.createdBy, "description": site.description, "groupType": site.groupType, "memberCount": site.memberCount, "name": site.name, "siteType": site.siteType, "siteKey":element.$key});
							//console.log(this.neighborhoodsDetails)
							this.accountService.currentSiteKey.subscribe(siteKey => {
								//console.log("siteKey", siteKey)
								this.siteKey = siteKey;
							});
							//alert("aaa")
							const filteredArr = this.neighborhoodsDetails.reduce((acc, current) => {
								const x = acc.find(item => item.siteKey === current.siteKey);
								if (!x) {
									return acc.concat([current]);
								} else {
									return acc;
								}
							}, []);
							this.neighborhoodsDetails = filteredArr;
							//console.log("this.neighborhoodsDetails", this.neighborhoodsDetails)
							//this.neighborhoodsevents.emit(filteredArr);										
							

							this.currentSite = this.neighborhoodsDetails.find(ele=>ele.siteKey == this.siteKey);
							//console.log(this.currentSite)
							//this.elementRef.nativeElement.innerHTML = site.name;
						}

					});
			} else {

				this.accountService.currentSiteKey.subscribe(siteKey => {

					this.sitesService.getGroup(siteKey)
						.valueChanges()
						.subscribe((site: any) => {

							//console.log("name", site.name)
							if (site && site.name) {
								this.neighborhoodsDetails.push({ "site": site, "siteKey": element.$key });
								//this.elementRef.nativeElement.innerHTML = site.name;											
							}

						});
				});
			}
		});

		//console.log(this.neighborhoodsDetails)
	}


	ngOnInit() {
		//this.site = this.sitesService.getCurrentNeighborhood();		
		this.backgroundImageGroup = "/assets/images/group-mockup.jpg";
		this.backgroundImageCommunity = "/assets/images/community-mockup.jpg";
		this.initialize();
		this.accountService.isAuthenticated.subscribe(isAuthenticated => {
			this.isAuthenticated = isAuthenticated;
			
			if (isAuthenticated) {
				this.hasNewNotifications = this.notificationsService.hasNewNotifications().valueChanges();

				this.notifications = this.notificationsService.getNotifications()
					.snapshotChanges()
					.pipe(map(snapshots => snapshots.map(snapshot => Object.assign({ key: snapshot.key }, snapshot.payload.val()))))
					

				this.notificationsService.getNotifications()
					.valueChanges()
					.subscribe((notifications: any) => {
						this.unreadNotificationCount = notifications.map(notification => notification.action)
						.filter(isRead => !isRead).length;
					});
			}
		});
	}

	join(groupKey, createdBy) {	
		
		var groupObj = {count:0};
		var subscriptionObj = {status:''};
		var canJoin = false;
				
		let promises = Promise.all(
			[
				this.sitesService.getUserStatus2(this.accountService.currentUser.uid, subscriptionObj, groupObj)
			]
		);

		promises.then(() => {	
/* 			if (subscriptionObj.status == 'essential' && groupObj.count < 1)
				canJoin = true;
			else if (subscriptionObj.status == 'plus' && groupObj.count < 2)
				canJoin = true;
			else if (subscriptionObj.status == 'premium' && groupObj.count < 6)
				canJoin = true;			

			if (canJoin)
			{*/
				this.sitesService.addMember(this.accountService.currentUser.uid, groupKey, false);	
				groupObj.count++;
				this.sitesService.updateUserGroupStatus(this.accountService.currentUser.uid, groupObj);
				this.notificationsService.removeNotification(groupKey, createdBy);
				this.accountService.setCurrentSite(groupKey);
			/* } 
			else
			{
				alert('Your current subscription plan is full. If you wish to add one or more Wissle groups, please Premium for $1 or $2 per month.');
				this.checkoutService.setJoinGroupKey(groupKey);	
				this.checkoutService.setJoinGroupCreatedBy(createdBy);
				this.router.navigate(['/neighborhood/create'], { queryParams: { communityType: 'Group', subscriptionType: subscriptionObj.status }});
			} */
				
			
		}, error => {					
			alert(error)
		});										
	}

	initialize() {
				
		this.neighborhoods.subscribe(a => {
			//console.log('s', JSON.stringify(a))
			this.getNeighborhoodsDetails(a);
						
			let promises = Promise.all(
				[
					this.accountService.getCurrentSite(this.siteObj)
				]
			);
	
			promises.then(() => {					
				this.selectNeighborhood(this.siteObj.key);							
			}, error => {					
				alert(error)
			});	
		
			this.checkoutService.clearJoinGroupKey();  // just in case the user cancelled the join at some point
			this.checkoutService.clearJoinGroupCreatedBy();
		})
	}

	selectNeighborhood(key) {
		if (key != 'default key') {
			this.accountService.setCurrentSite(key);
			this.currentSite = this.neighborhoodsDetails.find(ele => ele.siteKey == key);
			//console.log(JSON.stringify(this.currentSite));						
			this.changeNeighborhood(key);
		}
	}

	changeNeighborhood(key) {
		
		this.sitesService.changeNeighborhood(key);
	}

}
