import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { GroupsService } from "app/groups/groups.service";

@Component({
	selector: 'app-groups-member-list',
	templateUrl: './groups-member-list.component.html',
	styleUrls: ['./groups-member-list.component.css']
})
export class GroupsMemberListComponent implements OnInit, OnChanges {

	@Input() groupKey: string;

	memberKeys: any;
	currentUserIsAdministrator = false;

	constructor(private groupsService: GroupsService) { }

	ngOnInit() {
	}

	ngOnChanges() {
		this.memberKeys = this.groupsService.getMemberKeys(this.groupKey);
		this.groupsService.isAdministrator(this.groupKey)
			.subscribe(isAdmin => this.currentUserIsAdministrator = isAdmin);
	}

	removeMember(uid) {
		this.groupsService.removeMember(uid, this.groupKey);
	}
}
