import { Component, OnInit } from '@angular/core';
import { ContactService } from "app/core/services/contact.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-signup-later',
  templateUrl: './signup-later.component.html',
  styleUrls: ['./signup-later.component.css']
})
export class SignupLaterComponent implements OnInit {
  email = '';
  category = '';

  constructor(private contactService: ContactService, private router: Router) { }

  ngOnInit() {
  }
  submit() {
    this.contactService.remind(this.email, this.category)
        .then(() => {
            this.email = '';
            this.category = '';
            this.router.navigate(['/marketing/contact/thank-you']);
        })
}

}
