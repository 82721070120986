
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CamerasRoutingModule } from 'app/cameras/cameras-routing.module'
import { NeighborhoodModule } from 'app/neighborhood/neighborhood.module';
import { SharedModule } from 'app/shared/shared.module';
import { CamerasComponent } from './cameras/cameras.component';
import { TooltipModule, BsDropdownModule, TabsModule } from 'ngx-bootstrap';
import { FilesModule } from './../files/files.module';
import { CommentsModule } from 'app/comments/comments.module';
import { FormsModule } from '@angular/forms';
import { CamerasItemComponent } from './cameras-item/cameras-item.component'
import { CamerasItemModalComponent } from './cameras-item-modal/cameras-item-modal.component'
import { CamerasItemCreateComponent } from './cameras-item-create/cameras-item-create.component'
import { CamerasItemEditComponent } from './cameras-item-edit/cameras-item-edit.component'
import { CamerasListComponent } from './cameras-list/cameras-list.component'
import { CamerasFullscreenComponent } from './cameras-fullscreen/cameras-fullscreen.component'

import { PeopleModule } from "app/people/people.module";
import { GroupsModule } from "app/groups/groups.module";
import { MenusModule } from "app/menus/menus.module";
import { RlTagInputModule } from 'app/shared/tag-input/tag-input.module';


@NgModule({
    imports: [
		CommonModule,
		FormsModule,
		NeighborhoodModule,
		PeopleModule,
		CommentsModule,
		FilesModule,
		RlTagInputModule,
		GroupsModule,
		SharedModule,
        MenusModule,
        CamerasRoutingModule,
		
		TooltipModule.forRoot(),
		BsDropdownModule.forRoot(),
		TabsModule.forRoot(),
    ],
    declarations: [CamerasComponent, CamerasItemComponent, CamerasItemCreateComponent, CamerasItemEditComponent, CamerasItemModalComponent, CamerasListComponent,CamerasFullscreenComponent],
    exports: [CamerasComponent],
})
export class CamerasModule { }
