import { Component, OnInit } from '@angular/core';
import { AccountService } from 'app/core/services/account.service';
import { SitesService } from 'app/core/services/sites.service';
import { NotificationsService } from './../../notifications/notifications.service';
import { HOAComponent } from 'app/marketing/hoa/hoa.component';
import { group } from '@angular/animations';
import { Router } from '../../../../node_modules/@angular/router';
import { CheckOutService} from 'app/core/services/checkout.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-group-invite-list',
  templateUrl: './group-invite-list.component.html',
  styleUrls: ['./group-invite-list.component.css']
})
export class GroupInviteListComponent implements OnInit {

	isAuthenticated = false;
	hasNewNotifications: any;
	unreadNotificationCount: any;
	notifications: any;
  	neighborhoodsDetails = [];
	site: any;
	siteKey: any;
	currentSite: any;
	neighborhoods: any;
	currentUserIsAdministrator = false;

	backgroundImageGroup;
	backgroundImageCommunity;

	siteObj = {key: ''};
	adminCount = 0;	

	constructor(private sitesService: SitesService,
				private accountService: AccountService,
				private notificationsService: NotificationsService,				
				private router: Router,
				private checkoutService: CheckOutService) { 
					this.neighborhoods = this.sitesService.getNeighorhoods()
					.snapshotChanges().pipe(map(snapshots => snapshots.map(snapshot => {
					return { $key: snapshot.key }
					})));

					//console.log(this.neighborhoods)
				this.neighborhoods.subscribe(a => {
					//console.log('s', JSON.stringify(a))
					this.getNeighborhoodsDetails(a);
					if (a.length > 0)					
						this.selectNeighborhood(a[0].$key);
										
				})
				}
				getNeighborhoodsDetails(a){
		
					a.forEach(element => {
						//
						if (element.$key) {
						
							this.sitesService.getGroup(element.$key)
								.valueChanges()
								.subscribe((site: any) => {
									
									//console.log("name", site.name)
									if(site && site.name){
										var noImage = false;
										if (site.backgroundImage.downloadURL == 'assets/images/community-mockup.jpg')					
											noImage = true;

										var isGroup = false;
										if (site.groupType == 'essential' || site.groupType == 'plus' || site.groupType == 'premium')
											isGroup = true;
										this.neighborhoodsDetails.push({"address": site.address, "noImage":noImage, "isGroup":isGroup, "backgroundImage":site.backgroundImage.downloadURL[0], "createdBy": site.createdBy, "description": site.description, "groupType": site.groupType, "memberCount": site.memberCount, "name": site.name, "siteType": site.siteType, "siteKey":element.$key});
										//this.neighborhoodsDetails.push({"address": site.address,  "createdBy": site.createdBy, "description": site.description, "groupType": site.groupType, "memberCount": site.memberCount, "name": site.name, "siteType": site.siteType, "siteKey":element.$key});
										//console.log(this.neighborhoodsDetails)
										this.accountService.currentSiteKey.subscribe(siteKey => {
											//console.log("siteKey", siteKey)
											this.siteKey = siteKey;
									});
									//alert("aaa")
									const filteredArr = this.neighborhoodsDetails.reduce((acc, current) => {
										const x = acc.find(item => item.siteKey === current.siteKey);
										if (!x) {
										  return acc.concat([current]);
										} else {
										  return acc;
										}
									  }, []);
									  this.neighborhoodsDetails = filteredArr;
									 //console.log("this.neighborhoodsDetails", this.neighborhoodsDetails)
										//this.neighborhoodsevents.emit(filteredArr);
										
										this.currentSite = this.neighborhoodsDetails.find(ele=>ele.siteKey == this.siteKey);
										
										//this.elementRef.nativeElement.innerHTML = site.name;
									}
									
								});
						} else {
							
							this.accountService.currentSiteKey.subscribe(siteKey => {
								
								this.sitesService.getGroup(siteKey)
									.valueChanges()
									.subscribe((site: any) => {
										
										//console.log("name", site.name)
										if(site && site.name){
											this.neighborhoodsDetails.push({"site": site, "siteKey":element.$key});
											//this.elementRef.nativeElement.innerHTML = site.name;
										}
										
									});
							});
						}
					});
			
					//console.log(this.neighborhoodsDetails)
					
				}
				

	ngOnInit() {
		this.backgroundImageGroup = "/assets/images/group-mockup.jpg";
		this.backgroundImageCommunity = "/assets/images/community-mockup.jpg";

		this.accountService.isAuthenticated.subscribe(isAuthenticated => {
			this.isAuthenticated = isAuthenticated;
			
			if (isAuthenticated) {
				this.hasNewNotifications = this.notificationsService.hasNewNotifications().valueChanges();

				this.notifications = this.notificationsService.getNotifications()
					.snapshotChanges()
					.pipe(map(snapshots => snapshots.map(snapshot => Object.assign({ key: snapshot.key }, snapshot.payload.val()))))
					
				/*	
					.forEach((item) => {
						//console.log("item", item);
						item.forEach(function(rec, index, object) {
							if (rec['action'] == '/neighborhood/members') {
							  //object.splice(index, 1);							  
							}
						  });					 						
						  //return item;
					});
					*/

				this.notificationsService.getNotifications()
					.valueChanges()
					.subscribe((notifications: any) => {
						this.unreadNotificationCount = notifications.map(notification => notification.action)
						.filter(isRead => !isRead).length;
					});
			}
		});

		let promisess = Promise.all(
			[
				this.accountService.getCurrentSite(this.siteObj)
			]
		);

		promisess.then(() => {	
			this.sitesService.isAdministrator(this.siteObj.key)
			.subscribe(isAdmin => this.currentUserIsAdministrator = isAdmin);

			this.accountService.currentSiteKey.subscribe(siteKey => {
				this.sitesService.getMemberKeys(siteKey)
					.subscribe(memberKeys => {
						memberKeys.forEach(key => {										
							this.sitesService.isMemberAdministrator(this.siteObj.key, key)
							.subscribe(isAdmin => {if (isAdmin) this.adminCount++});
						});
						
					});
			});
		}, error => {					
			alert(error)
		});

		this.sitesService.currentMessageKey.subscribe(message => {this.selectNeighborhood(message);})
		this.accountService.isNeighborhoodAdministrator
				.subscribe(isAdmin => {
					this.currentUserIsAdministrator = isAdmin;
				});	
		
		if (this.currentSite == null)
		{
			var tempSite = {name: ''};
			this.currentSite = tempSite;
		}
	}

	join(groupKey, createdBy) {	
		var groupObj = {count:0};
		var subscriptionObj = {status:''};
		var canJoin = false;
	
		let promises = Promise.all(
			[
				this.sitesService.getUserStatus2(this.accountService.currentUser.uid, subscriptionObj, groupObj)
			]
		);

		promises.then(() => {	

/* 			if (subscriptionObj.status == 'essential' && groupObj.count < 1)
				canJoin = true;
			else if (subscriptionObj.status == 'plus' && groupObj.count < 2)
				canJoin = true;
			else if (subscriptionObj.status == 'premium' && groupObj.count < 6)
				canJoin = true;

			if (canJoin)
			{ */
				this.sitesService.addMember(this.accountService.currentUser.uid, groupKey, false);	
				groupObj.count++;
				this.sitesService.updateUserGroupStatus(this.accountService.currentUser.uid, groupObj);
				this.notificationsService.removeNotification(groupKey, createdBy);
				this.accountService.setCurrentSite(groupKey);
/* 			}
			else
			{
				alert('Your current subscription plan is full. If you wish to add one or more Wissle groups, please Premium for $1 or $2 per month.');
				
				this.checkoutService.setJoinGroupKey(groupKey);	
				this.checkoutService.setJoinGroupCreatedBy(createdBy);
				this.router.navigate(['/neighborhood/create'], { queryParams: { communityType: 'Group', subscriptionType: subscriptionObj.status }});
			}
				 */
			
		}, error => {					
			alert(error)
		});										
	}

	decline(groupKey, createdBy) {			
		this.notificationsService.removeNotification(groupKey, createdBy);
	}

	clearNotifications() {
		this.notificationsService.clearNewNotifications();
	}	

	selectNeighborhood(key) {	

		if (key != 'default key'){	
			this.siteKey = key;		
			if (this.neighborhoodsDetails.length > 0)
			{
				this.currentSite = this.neighborhoodsDetails.find(ele=>ele.siteKey == key);
				if (this.currentSite != null)
				{
					if (this.currentSite.noImage)
					{
						if (this.currentSite.isGroup)
							this.currentSite.backgroundImage = this.backgroundImageGroup;
						else
							this.currentSite.backgroundImage = this.backgroundImageCommunity;
					}
				}
				
			}
			
			this.accountService.isNeighborhoodAdministrator
				.subscribe(isAdmin => {
					this.currentUserIsAdministrator = isAdmin;
				});

			//console.log(JSON.stringify(this.currentSite))
		}				
	}

	removeFromGroup() {		

		var groupObj = {count:0};
		var subscriptionObj = {status:''};	
		var groupType = this.currentSite.groupType;

		var communityBoughtObj = {count: 0};
        var communityObj = {status:''};
        var communityGroupObj = {count: 0};

		this.sitesService.removeMember(this.accountService.currentUser.uid, this.siteKey);			
		let promises = Promise.all(
			[
				this.sitesService.getUserStatus2(this.accountService.currentUser.uid, subscriptionObj, groupObj),
				this.sitesService.getUserCommunityInfo2(this.accountService.currentUser.uid, communityObj, communityGroupObj, communityBoughtObj)
			]
		);

		promises.then(() => {	
			
			groupObj.count--;
			if (groupObj.count < 0)
				groupObj.count = 0;

			this.sitesService.updateUserGroupStatus(this.accountService.currentUser.uid, groupObj);						
			
			if (groupObj.count == 0 && communityGroupObj.count == 0)							
				this.router.navigate(['/neighborhood', 'create']);  			
			else
				window.location.reload();	
						
		}, error => {					
			alert(error)
		});
		
	}
	// displayData(event){
	// 	var filteredArr = event.reduce((acc, current) => {
	// 		const x = acc.find(item => item.siteKey === current.siteKey);
	// 		if (!x) {
	// 		  return acc.concat([current]);
	// 		} else {
	// 		  return acc;
	// 		}
	// 	  }, []);
	// 	  this.neighborhoods = [];
	// 	this.neighborhoods = filteredArr;
	// }

	// currentSiteDetail(event){
	// 	this.currentSite = {};
	// 	this.currentSite = event;
	// 	//console.log("event", event)
	// }
	

}

