
import { PeopleService } from './people.service';
import { Directive, Input, OnChanges, ElementRef } from '@angular/core';


@Directive({
	selector: '[profileImageSrc]'
})
export class ProfileImageSrcDirective implements OnChanges {

	@Input('profileImageSrc') key: string;

	person: any;

	constructor(private peopleService: PeopleService, private elementRef: ElementRef) { }

	ngOnChanges() {
		this.peopleService.getPersonByKey(this.key)
			.valueChanges()
			.subscribe((person: any) => {
				//console.log(person.profileImage.downloadURL[0]);
				// person.profileImage.downloadURL[0] =	person.profileImage.downloadURL[0].replace('https://storage.googleapis.com/', '/api/');
				// //console.log(person.profileImage.downloadURL[0]);
				// this.appEngineService.getImageService(person.profileImage.downloadURL).subscribe(res=>{
				// 	//console.log("susssssssss" , res)
				// 	this.elementRef.nativeElement.src = person.profileImage ? person.profileImage.downloadURL : 'assets/images/default-profile.png';
				// },error=>{
				// 	//console.log("susssssssss error" , error)
				// 	this.elementRef.nativeElement.src ='assets/images/default-profile.png';
				// })
				this.elementRef.nativeElement.src =
					person.profileImage ? person.profileImage.downloadURL :
						'assets/images/default-profile.png';
			});
	}

}
