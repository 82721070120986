import { ContactService } from './../../core/services/contact.service';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContactRoutingModule } from './contact-routing.module';
import { ContactHelpComponent } from './contact-help/contact-help.component';
import { ContactJoinComponent } from './contact-join/contact-join.component';
import { ContactQuestionsComponent } from './contact-questions/contact-questions.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { CheckOutService } from './../../core/services/checkout.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ContactRoutingModule
    ],
    providers: [
        ContactService, CheckOutService
    ],
    declarations: [ContactHelpComponent, ContactJoinComponent, ContactQuestionsComponent, ThankYouComponent]
})
export class ContactModule { }
