import { FaqComponent } from './faq/faq.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TosComponent } from './tos/tos.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { HOAComponent } from './hoa/hoa.component';
import { JoinComponent } from './join/join.component';
import { SignupLaterComponent } from './signup-later/signup-later.component'
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'marketing/home', component: HomeComponent },
    { path: 'marketing/about', component: AboutComponent },
    { path: 'marketing/faq', component: FaqComponent },
    { path: 'marketing/privacy-policy', component: PrivacyPolicyComponent },
    { path: 'marketing/terms-conditions', component: TosComponent },
    { path: 'marketing/hoa', component: HOAComponent },
    { path: 'marketing/signup-later', component: SignupLaterComponent },
    { path: 'marketing/join', component: JoinComponent}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MarketingRoutingModule { }
