import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from '@angular/platform-browser';
import { SitesService } from "app/core/services/sites.service";

@Component({
    selector: 'app-cameras-item-create',
    templateUrl: './cameras-item-create.component.html',
    styleUrls: ['./cameras-item-create.component.css']
})
export class CamerasItemCreateComponent implements OnInit {

    @Output() added = new EventEmitter<boolean>();
    @Output() cancelled = new EventEmitter<boolean>();
    @Input() siteKey: string;
    cameraURL: string;
    cameraDesc: string;

    constructor(private activatedRoute: ActivatedRoute, 
        private titleService: Title,
        private router: Router,
        private siteService: SitesService) {
        titleService.setTitle('Wissle Cameras Create');
     }

    ngOnInit() {
        //debugger;
        // this.activatedRoute.params
		// 	.subscribe(params => {
		// 		this.key = params.key;
		// 	});
    }

    submit() {
        // debugger;
        // console.log("CAMERA ID: ", this.cameraURL);
        
        // strip out all the amp; characters so that the dom sanitizer will return a legit url
        while (this.cameraURL.indexOf('amp;') > 0)
            this.cameraURL = this.cameraURL.replace('amp;', '');
        
        this.siteService.addCamera(this.siteKey, this.cameraURL, this.cameraDesc);
        this.added.emit();
    }

    cancel() {
        this.cancelled.emit();
    }
}
 