import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { SitesService } from "app/core/services/sites.service";
import { AgmMap, AgmPolygon, GoogleMapsAPIWrapper } from "@agm/core";
declare var google;

@Component({
	selector: 'app-neighborhood-map-edit',
	templateUrl: './neighborhood-map-edit.component.html',
	styleUrls: ['./neighborhood-map-edit.component.css']
})
export class NeighborhoodMapEditComponent implements OnInit {

	@ViewChild(AgmMap) map: AgmMap;
	@Output() close = new EventEmitter();

	latitude = 39.7226191;
	longitude = -95.9318575;
	zoom = 4;
	paths = [
		{ lat: 38, lng: -94 },
		{ lat: 38, lng: -96 },
		{ lat: 40, lng: -94 },
	];
	polygon: any;

	constructor(private sitesService: SitesService, private mapa: GoogleMapsAPIWrapper) { }

	ngOnInit() {
		this.sitesService.getSiteMap()
			.subscribe(siteMap => {
				if (siteMap) {

					this.latitude = siteMap.latitude;
					this.longitude = siteMap.longitude;
					this.zoom = siteMap.zoom;

					if (siteMap.paths) {
						this.paths = siteMap.paths;
						this.map.mapReady.subscribe(map => {
							this.polygon = new google.maps.Polygon({
								paths: this.paths,
								editable: true,
								draggable: true
							});
							this.polygon.setMap(map);
							this.reloadMap();
						});
					}
				}
			});
	}

	reloadMap() {
		this.map.triggerResize();
	}

	submit() {
		let path = [];
		this.polygon.getPath().forEach(latLng => {
			path.push({
				lat: latLng.lat(),
				lng: latLng.lng()
			});
		});

		this.sitesService.updateMap(path, this.map.zoom, this.map.latitude, this.map.longitude);
		this.close.emit();
	}
	cancel() {
		this.close.emit();
	}
}
