import { DiscussionService } from 'app/discussion/discussion.service';
import { Component, OnInit, OnChanges, Input, ViewChild, Output, EventEmitter} from '@angular/core';
import { AccountService } from "app/core/services/account.service";
import { ModalComponent } from "app/shared/modal/modal.component";
import { SitesService } from "app/core/services/sites.service";
import { CommentsService } from "app/comments/comments.service";

@Component({
	selector: 'app-discussion-item',
	templateUrl: './discussion-item.component.html',
	styleUrls: ['./discussion-item.component.css']
})
export class DiscussionItemComponent implements OnInit, OnChanges {

	@Input() key: string;
	@Input() tag: string;
	@Input() indexOfelement: number;
	@Input() searchFilter: string;
	@Input() searchFilterDate: string;

	@Output() commentsSelected = new EventEmitter<string>();

	@ViewChild('deleteModal') deleteModal: ModalComponent;
 
	discussion: any;
	canDelete = false;
 
	discussionObj = {count: 0};
	memberCountObj = {count: 0};
	groupTypeObj = {status: '', name: ''};
	isGroup: boolean;

	objCount = {count: 0};
	prevObjCount = {count: 0};
  source = 'Discussion';  // helps load the comments from first to last 
	
	constructor(private discussionService: DiscussionService,
		        private accountService: AccountService,
				private sitesService: SitesService,
				private commentsService: CommentsService) { }

	ngOnInit() {
		this.sitesService.currentMessageKey.subscribe(message => {this.selectNeighborhood(message);})
		this.commentsService.getCommentCount(this.key, this.objCount, this.prevObjCount);	
		this.discussion = this.discussionService.getItem(this.key).valueChanges();
		this.accountService.isNeighborhoodAdministrator
			.subscribe(isAdmin => {
				this.canDelete = isAdmin;
			});		
	}

	ngOnChanges() {
		
	}

	isHovering(val)
	{
		this.canDelete = (val.createdBy === this.accountService.currentUser.uid);		
	}

	delete() {
		if (confirm('Press OK to delete discussion item'))
			this.discussionService.delete(this.key);
	}

	selectNeighborhood(key) {		
		if (key != 'default key')
		{
			//this.key = key;				
			let promises = Promise.all(
				[this.sitesService.getCurrentMemberCount(key, this.memberCountObj, this.groupTypeObj)]
			);
	
			promises.then((result) => {	
				if (this.groupTypeObj.status == 'small' || 
					this.groupTypeObj.status == 'medium' || 
					this.groupTypeObj.status == 'large')				
					this.isGroup = false;
				else
					this.isGroup = true;

				if (this.isGroup)
					this.tag = 'discussion board';				
			});
		}
        
    }
}
