import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactionsService } from "app/reactions/reactions.service";
import { ReactionsComponent } from './reactions/reactions.component';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [ReactionsComponent],
	exports: [ReactionsComponent],
	providers: [ReactionsService]
})
export class ReactionsModule { }
