import { CommentItemCreateComponent } from './../../comments/comment-item-create/comment-item-create.component';
import { ActivatedRoute } from '@angular/router';
import { CommentItemListComponent } from './../../comments/comment-item-list/comment-item-list.component';
import { DiscussionItemComponent } from './../discussion-item/discussion-item.component';
import { Component, OnInit, ViewChild, Input } from '@angular/core';

@Component({
    selector: 'app-discussion-item-modal',
    templateUrl: './discussion-item-modal.component.html',
    styleUrls: ['./discussion-item-modal.component.css']
})
export class DiscussionItemModalComponent implements OnInit {

    @ViewChild('discussionItem') discussionItem: DiscussionItemComponent;
    @ViewChild('comments') comments: CommentItemListComponent;    

    @ViewChild('commentCreate') commentCreate: CommentItemCreateComponent
    constructor(private activatedRoute: ActivatedRoute) { }

    tag = 'discussion board';  
    query = '';  
    queryDate = '';
    queryDateFormatted: string = '';
    source = 'Discussion';  // helps load the comments from first to last

    ngOnInit() {        
        this.activatedRoute.params.subscribe(params => {  
            this.tag = params.tag;
            this.query = params.query;
            this.queryDate = params.queryDate;
            this.discussionItem.key = params.key;
            this.comments.parentKey = params.key;
            this.commentCreate.parentKey = params.key;
            this.discussionItem.ngOnChanges();
            this.comments.ngOnChanges();
        })
    }

}
