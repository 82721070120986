import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { AppEngineService } from 'app/app-engine.service';
import { SitesService } from "app/core/services/sites.service";
import { NotificationsService } from 'app/notifications/notifications.service';
import { CheckOutService} from 'app/core/services/checkout.service';
import { Title } from '@angular/platform-browser';
import { AccountService } from './../../core/services/account.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    email = '';
    password = '';
    loggedIn: boolean = false;
    rememberMe: boolean = false;

    // from hoa/market page
    neighborhoodCreateUrl: string = '/';
    communityType: string = 'essential';
    subscriptionType: string = 'none';   
    inviteSiteKey: string = ''; 
    createdBy: string = '';

    // from non-members email link
    nonMemberSiteKey: string = '';
    nonMemberEmails: string = '';
    discussionUrl: string = '';
    alertUrl: string = '';
    
    joinSiteCode: string = '';
    joinObj = {found: '', siteKey: '', code: ''};

    memberCountObj = {count: 0};    
    groupTypeObj = {status: '', name: ''};

    nonMembers: any;

    constructor(private afAuth: AngularFireAuth,
        private router: Router,
        private route: ActivatedRoute,
        private sitesService: SitesService,
        private appEngine: AppEngineService,
		private notificationsService: NotificationsService,
        private checkoutService: CheckOutService,
        private titleService: Title,
        private accountService: AccountService) {
            titleService.setTitle('Wissle Profile');            
            this.route.params.subscribe( params => 
                this.joinSiteCode = params['code']);            
         }

    ngOnInit() {
        
        this.neighborhoodCreateUrl = this.route.snapshot.queryParams['forwardUrl'] || '/';
        this.communityType = this.route.snapshot.queryParams['communityType'] || 'essential';
        this.subscriptionType = this.route.snapshot.queryParams['subscriptionType'] || 'none'; 
        this.inviteSiteKey = this.route.snapshot.queryParams['inviteSiteKey'] || ''; 
        this.createdBy = this.route.snapshot.queryParams['createdBy'] || '';

        this.nonMemberSiteKey = this.route.snapshot.queryParams['nonMemberSiteKey'] || '';
        this.discussionUrl = this.route.snapshot.queryParams['discussionPath'] || '';
        this.nonMemberEmails = this.route.snapshot.queryParams['nonMemberEmails'] || '';
        this.alertUrl = this.route.snapshot.queryParams['alertPath'] || '';

      
        this.appEngine.auth.onAuthStateChanged((user) => {
			if (user) {        
                this.loggedIn = true;  
                
                if ((this.inviteSiteKey != '' &&
                    this.createdBy != '') ) {
                    this.checkAutoAcceptInvite(); 
                } 
                else if (this.joinSiteCode != undefined)
                {                                  
                    // do nothing. user will click the submit button 
                    // and checkAutoAcceptInvite will get called from there
                }
                else
                    this.enterNeighborhood();                                       
            } else {        
                this.loggedIn = false;  
                if (this.nonMemberSiteKey !== '')
                {
                    this.password = this.AutoGeneratePassword(this.nonMemberEmails);
                    //this.nonMembers = this.sitesService.getNonMembers(this.nonMemberSiteKey);
                } 
                else if (this.joinSiteCode != '' && this.joinSiteCode != undefined) {
                    this.router.navigate(['/new/join/' + this.joinSiteCode]);
                }
                    // alsfjaslfsafjsladkf

                //if there is a nonmembersitekey then check all the users that belong to this group
                 //disable the password field because it will be auto generated
            }
        });        
    }

    AutoGeneratePassword(emails: string) {                
        var sRetVal = Math.random().toString(36).slice(2, 8);                    
        return sRetVal;
    }

    CreateNewAccount() {     
           
        if (this.loggedIn) {
            this.router.navigate(['/neighborhood', 'alerts']);
        }
        else {
            if (this.joinSiteCode != '' && this.joinSiteCode != undefined) {
                this.router.navigate(['/new/join/' + this.joinSiteCode]);
            }
            else if (this.inviteSiteKey != '' && this.inviteSiteKey != undefined &&
            this.createdBy != '' && this.createdBy != undefined ) {
                this.router.navigate(['/new/join/' + 'inviteSiteKey=' + this.inviteSiteKey + '&createdBy=' + this.createdBy]);
            }
            else 
            {
                if (this.neighborhoodCreateUrl != '/')
                    this.router.navigate(['/signup'], { queryParams: { forwardUrl: '/neighborhood/create', communityType: this.communityType, subscriptionType: this.subscriptionType }});
                else
                    this.router.navigate(['/signup']);
            }
            
        }        
    }

    checkAutoAcceptInvite() {        
        var groupObj = {count:0};	
        var subscriptionObj = {status:''};
        var canJoin = false;        
                
        let promises = Promise.all(
            [
                this.sitesService.getUserStatus2(this.afAuth.auth.currentUser.uid, subscriptionObj, groupObj)                
            ]
        );

        promises.then(() => {            
            
            // if ((subscriptionObj.status == 'essential') && groupObj.count < 1)
            //     canJoin = true;
            // else if (subscriptionObj.status == 'plus' && groupObj.count < 2)
            //     canJoin = true;
            // else if (subscriptionObj.status == 'premium' && groupObj.count < 6)
            //    canJoin = true;

            //if (canJoin) {
                if (this.inviteSiteKey != '' &&
                    this.createdBy != '') {
                    this.sitesService.addMember(this.afAuth.auth.currentUser.uid, this.inviteSiteKey, false);
                    this.sitesService.removeNonMemberByEmail(this.inviteSiteKey, this.accountService.currentUser.email);
                }                    
                else if (this.joinObj.siteKey != ''){
                    this.sitesService.addMember(this.afAuth.auth.currentUser.uid, this.joinObj.siteKey, false);
                    this.sitesService.removeNonMemberByEmail(this.joinObj.siteKey, this.accountService.currentUser.email);
                }
                    
                groupObj.count++;
                this.sitesService.updateUserGroupStatus(this.afAuth.auth.currentUser.uid, groupObj);

                if (this.inviteSiteKey != '' &&
                    this.createdBy != ''){
                        this.notificationsService.removeNotification(this.inviteSiteKey, this.createdBy);
                        this.accountService.setCurrentSite(this.inviteSiteKey);
                }
                else {
                    this.accountService.setCurrentSite(this.joinObj.siteKey);
                }                
				        

                this.router.navigate(['neighborhood/members'], {queryParams: {welcome: 1}});
            // }
            // else{
            //     alert('Your current subscription plan is full. If you wish to add one or more Wissle groups, please choose Premium for $1 or $2 per month.');
            //     this.checkoutService.setJoinGroupKey(this.inviteSiteKey);	
            //     this.checkoutService.setJoinGroupCreatedBy(this.createdBy);
            //     this.router.navigate(['/neighborhood/create'], { queryParams: { communityType: 'Group', subscriptionType: subscriptionObj.status }});
            // }
                
            
        }, error => {					
            alert(error)
            this.router.navigate(['neighborhood/members']);
        });	
    }    

    enterNeighborhood() {
        var groupObj = {count:0};	
        var subscriptionObj = {status:''};
        
        var communityBoughtObj = {count: 0};
        var communityObj = {status:''};
        var communityGroupObj = {count: 0};
                            
        let promises = Promise.all(
            [
                this.sitesService.getUserStatus2(this.afAuth.auth.currentUser.uid, subscriptionObj, groupObj),
                this.sitesService.getUserCommunityInfo2(this.afAuth.auth.currentUser.uid, communityObj, communityGroupObj, communityBoughtObj)
            ]
        );

        promises.then(() => {
            if (groupObj.count == 0 && communityGroupObj.count == 0)    
                this.router.navigate(['/neighborhood', 'create']);           
            else
                this.router.navigate(['/neighborhood', 'alerts']);                                                            
        }, error => {					
            alert(error)
            this.router.navigate(['neighborhood/members']);
        });	  
    }

    // newSetup(uid: string, firstName: string, lastName: string, email: string, address: string, cell: any, photoURL, neighborhoodCreateUrl) {
	// 	var signup = this;
	// 	var groupObj = { count: 0 };
	// 	var emailRef = encodeURIComponent(btoa(email));
		
	// 	// format the cell phone back to ###-###-####
	// 	cell = this.changeCellNumberFormat(cell);		

	// 	this.afAuth.auth.currentUser.updateEmail(email)
	// 		.then(() =>
								
	// 			this.afAuth.auth.currentUser.updateProfile({
	// 				displayName: firstName + ' ' + lastName,
	// 				photoURL: photoURL
	// 			}).then(() => {
	// 				this.sitesService.signUpNewUser(uid,
	// 					firstName,
	// 					lastName,
	// 					email,
	// 					address,
	// 					cell
	// 				).then(() => {

	// 					let promises = [];  
						
	// 					if (this.address.length) {
	// 						promises.push(this.accountService.updateAddress(this.address, this.latitude, this.longitude));
	// 					}

	// 					if (this.data.image) {  
	// 						promises.push(this.updateProfileImage(this.file, this.data.image, uid));										
	// 					}

	// 					var persistenceType = firebase.auth.Auth.Persistence.LOCAL;  // first time the user is created they should stayed logged in
	// 					promises.push(firebase.auth().setPersistence(persistenceType));
									
	// 					Promise.all(promises)
	// 					.then(() => {
	// 					if ((this.inviteSiteKey != '' &&
	// 						this.createdBy != '') ||
	// 						this.joinObj.siteKey != '') {

	// 						if (this.joinObj.siteKey != '')
	// 						{
	// 							this.sitesService.addMember(this.accountService.currentUser.uid, this.joinObj.siteKey, false);
	// 							this.sitesService.removeNonMemberByEmail(this.joinObj.siteKey, this.accountService.currentUser.email);
	// 						}								
	// 						else
	// 						{
	// 							this.sitesService.addMember(this.accountService.currentUser.uid, this.inviteSiteKey, false);
	// 							this.sitesService.removeNonMemberByEmail(this.inviteSiteKey, this.accountService.currentUser.email);								
	// 						}
								
	// 						groupObj.count = 1;
	// 						this.sitesService.updateUserGroupStatus(this.accountService.currentUser.uid, groupObj);

	// 						if (this.joinObj.siteKey == '') {
	// 							this.notificationsService.removeNotification(this.inviteSiteKey, this.createdBy);
	// 							this.accountService.setCurrentSite(this.inviteSiteKey);
	// 						}
	// 						else {
	// 							this.accountService.setCurrentSite(this.joinObj.siteKey);
	// 						}														

	// 						this.router.navigate(['neighborhood/members'], {queryParams: {welcome: 1}});
	// 					}
	// 					else {
	// 						this.accountService.setCurrentSite(uid);
	// 						if (neighborhoodCreateUrl != '/')
	// 							this.router.navigate(['neighborhood/create'], { queryParams: { communityType: this.communityType, subscriptionType: this.subscriptionType, newuser: 1 } });
	// 						else
	// 							this.router.navigate(['neighborhood/create']);
	// 					}
							
													
	// 					}, (error) => {
	// 						alert(error);                
	// 					});							
	// 				});
	// 				//promises.push(this.accountService.updateCellPhone(this.cellPhone));
	// 			}, error => {
	// 				alert(error);
	// 				this.waitingModal.hide();
	// 			}));

	// 	if ((this.inviteSiteKey != '' &&
	// 		this.createdBy != '') ||
	// 		this.joinObj.siteKey != '') {
	// 		// do nothing
	// 	}
	// 	else {
	// 		let ref = firebase.database().ref(`signup-invitation/${emailRef}`).once('value');
	// 		ref.then(function (snapshot) {

	// 			if (snapshot.val() != null) {
	// 				var invited_sitename = snapshot.val().invited_sitename;
	// 				var message = snapshot.val().message;
	// 				var invited_site_key = snapshot.val().invited_site_key;
	// 				var createdBy = snapshot.val().createdBy;
	// 				var siteType = snapshot.val().siteType;
	// 				var memberKeys = [uid];
	// 				var site = snapshot.val();
	// 				signup.notificationsService.afterSignUpNotification(site, invited_sitename, message, uid, invited_site_key, createdBy, siteType);
	// 			}

	// 		});
	// 	}
	// }

    join(groupKey) {	
		
		var groupObj = {count:0};
		var subscriptionObj = {status:''};						
		var nonUserObj = {firstName: '', lastName: ''};
        
        this.afAuth.auth.currentUser.updateEmail(this.email).then(() => 
        this.afAuth.auth.currentUser.updateProfile({
            displayName: this.email
        }) ).then(() => {
            this.sitesService.getNonMember(this.nonMemberSiteKey, this.email, nonUserObj).
              
        then(() =>{
            
            this.sitesService.signUpNewUser(this.afAuth.auth.currentUser.uid,
                 					nonUserObj.firstName,
                 					nonUserObj.lastName,
                                    this.email,
                                    '', // address
                                    '', //cell
                 				).then(() => {
                                    let promises = Promise.all(
                                        [
                                            this.sitesService.getUserStatus2(this.afAuth.auth.currentUser.uid, subscriptionObj, groupObj),                                                                                        
                                        ]
                                    );
                            
                                    promises.then(() => {	
                                                                    
                                            this.sitesService.addMember(this.afAuth.auth.currentUser.uid, groupKey, false);	
                                            groupObj.count++;
                                            this.sitesService.updateUserGroupStatus(this.afAuth.auth.currentUser.uid, groupObj);
                                            
                                            this.accountService.setCurrentSite(groupKey);
                                            this.sitesService.removeNonMemberByEmail(this.nonMemberSiteKey, this.email); 
                                            if (this.discussionUrl != '')
                                                this.router.navigate(['/neighborhood', 'discussion']);								
                                            else 
                                                this.router.navigate(['/neighborhood', 'alerts']);								
                                    }, error => {					
                                        alert(error)
                                    });		
                                })
        })
    })
										
	}

    submit() {  
        if (this.loggedIn) {    
                       
            var persistenceType = firebase.auth.Auth.Persistence.SESSION;
            if (this.rememberMe)
                persistenceType = firebase.auth.Auth.Persistence.LOCAL;

            firebase.auth().setPersistence(persistenceType).then(() => {
                if (this.joinSiteCode != '' && this.joinSiteCode != undefined) {
                    let promises = Promise.all(
                        [
                            this.accountService.getSiteJoinKey(this.joinSiteCode, this.joinObj)
                        ]
                    );
        
                    promises.then(() => {  
                        if (this.joinObj.siteKey != '') {
                            this.checkAutoAcceptInvite(); 
                        }
                    }, error => {					
                        alert(error);            
                    });	
                }
                else if (this.inviteSiteKey != '' &&
                    this.createdBy != '')  {
                    this.checkAutoAcceptInvite(); 
                }
                else {
                    this.enterNeighborhood();
                }
            })                        
        }
        else {   
                        
            var email = this.email;            
            var bAutoRegister = false;  // assume it's a member so no auto register to start
            var password;
            if (this.nonMemberSiteKey !== '')
            {
                if (this.nonMemberEmails.includes(email))
                {
                    password = this.password
                    bAutoRegister = true;
                }                    
                else
                    password = this.password;
            } 
            else
                password = this.password;
            
            if (bAutoRegister)  // create the new account
            {
                this.afAuth.auth.createUserWithEmailAndPassword(this.email, password)
                .then(user => {
                    if (user) {
                        
                        this.join(this.nonMemberSiteKey);
                                               
                    }
                }, error => {
                    alert(error.message);

                    return;
                });
                
            }
            else {  // not auto register
                var persistenceType = firebase.auth.Auth.Persistence.SESSION;
                if (this.rememberMe)
                    persistenceType = firebase.auth.Auth.Persistence.LOCAL;
    
                firebase.auth().setPersistence(persistenceType)
                    .then(() => {
                        // Existing and future Auth states are now persisted in the current
                        // session only. Closing the window would clear any existing state even
                        // if a user forgets to sign out.
                        // ...
                        // New sign-in will be persisted with session persistence.
                        return firebase.auth().signInWithEmailAndPassword(email, password).then(() => {
    
                            if (this.joinSiteCode != '' && this.joinSiteCode != undefined) {
                                let promises = Promise.all(
                                    [
                                        this.accountService.getSiteJoinKey(this.joinSiteCode, this.joinObj)                                    
                                    ]
                                );
    
                                promises.then(() => {                                  
                                    if (this.joinObj.siteKey != '') {
                                        this.checkAutoAcceptInvite(); 
                                    }
                                }, error => {					
                                    alert(error);            
                                });	
                    
                                // promises.then(() => {                                  
                                //     if (this.joinObj.siteKey != '') {   
                                //         let promises2 = Promise.all(
                                //             [
                                //                 this.sitesService.getCurrentMemberCount(this.joinObj.siteKey, this.memberCountObj, this.groupTypeObj)                                  
                                //             ]
                                //         );
                                //         promises2.then(() => {                                  
                                //             if (this.joinObj.siteKey != '') {                                    
                                //                 this.checkAutoAcceptInvite(); 
                                //             }
                                //         }, error => {					
                                //             alert(error);            
                                //         });
    
                                         
                                //     }
                                // }, error => {					
                                //     alert(error);            
                                // });	
    
                                
                            } 
                            else if (this.inviteSiteKey != '' &&
                                this.createdBy != '') {
                                this.checkAutoAcceptInvite(); 
                            }
                            else {
                                if (this.neighborhoodCreateUrl != '/'){
                                    //console.log('community type', this.communityType);
                                    this.router.navigate([this.neighborhoodCreateUrl], {queryParams: {communityType: this.communityType, subscriptionType: this.subscriptionType}});
                                }                    
                                else{   
                                    this.enterNeighborhood();                                            
                                }
                            }
                                
                        }, error => {                    
                            if (this.joinSiteCode != '') {
                                alert('We dont recognize this email or password, try again or create a new users');
                               /*  this.router.navigate(['/new/join/' + this.joinSiteCode]); */
                            }
                            else
                                alert(error.message);
                        });
                    })
                    .catch(function(error) {
                        // Handle Errors here.
                        var errorCode = error.code;
                        var errorMessage = error.message;
                    });
            }
                        
        }
        
    }

    hasRoomLeft() {
        var bCanAdd = false;
        
        if (this.groupTypeObj.status == 'small')
        {								
            if (this.memberCountObj.count < 100)
                bCanAdd = true;
            else
                alert('There are already 100 members in your community.');				            
        }
        else if (this.groupTypeObj.status == 'medium')
        {								
            if (this.memberCountObj.count < 200)
                bCanAdd = true;
            else
                alert('There are already 200 members in your community.');								
        }
        else if (this.groupTypeObj.status == 'large')
        {								
            if (this.memberCountObj.count < 500)
                bCanAdd = true;
            else
                alert('There are already 500 members in your community.');								
        }
        else if (this.groupTypeObj.status == 'essential' ||
                this.groupTypeObj.status == 'plus' ||
                this.groupTypeObj.status == 'premium')
        {				
            if (this.memberCountObj.count < 25)
                bCanAdd = true;
            else
                alert('There are already 25 members in your group.');								
        }
        else {
            alert("Your group subscription is '" + this.groupTypeObj.status + "' which is not one of the possibilities: small, medium, large, essential, plus, and premium.");
        }
        return bCanAdd;
    }

    signinGoogle() {
        let provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then((result) => {
            this.router.navigate(['/neighborhood', 'alerts']);
        }, error => alert(error.message));
    }

    signinFacebook() {
        let provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().signInWithPopup(provider).then((result) => {
            this.router.navigate(['/neighborhood', 'alerts']);
        }, error => alert(error.message));
    }
}
