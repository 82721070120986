import { ModalComponent } from 'app/shared/modal/modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { AccountService } from './../../core/services/account.service';
import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { SitesService } from 'app/core/services/sites.service';
import * as firebase from 'firebase';
import { PeopleService } from 'app/people/people.service';
//import { MapsAPILoader } from '@agm/core';
import { AngularFireDatabase } from "angularfire2/database";
import { NotificationsService } from 'app/notifications/notifications.service';
import { Title } from '@angular/platform-browser';
import { ImageCroppedEvent } from 'ngx-image-cropper';
declare var google: any;


@Component({
	selector: 'app-new-user',
	templateUrl: './new-user.component.html',
	styleUrls: ['./new-user.component.css']
})
export class NewUserComponent implements OnInit {

	@ViewChild(ModalComponent) waitingModal: ModalComponent;
	@ViewChild(ModalComponent) terms: ModalComponent;

	private _peopleRef = this.db.database.ref('people');
	private geocoder: any;

	data: any;
	file: any;

	email = '';
	address = '';
	googleLoaded = false;
	password = '';
	firstName = '';
	lastName = '';
	cellNumber = '';
	newPhoneNumber = '';
	newPhoneNumberType = 'Cell';
	showMap = false;
	latitude = 0;
	longitude = 0;
	zoom = 18;
	cellPhone = '';
	saving = false;
	addressValid = false;

	signupToken = '';

	invitationRefKey = '';
	element: HTMLInputElement;
	neighborhoodCreateUrl: string = '/';
	communityType: string = 'essential';
	subscriptionType: string = 'none';
	inviteSiteKey: string = '';
	createdBy: string = '';

	memberCountObj = { count: 0 };
	groupTypeObj = { status: '', name: '' };
	groupObj = { count: 0 };
	agreeButtonPressed = false;
	isSaving = false;

	joinSiteCode: string = '';
	joinObj = { found: '', siteKey: '', code: '' };
	siteObj = { name: '' }

	step: number = 0;
	tabs = [{ active: false }, { active: false }, { active: false }, { active: false }, { active: false }, { active: false }];

	imageChangedEvent: any = '';
	croppedImage: any = '';
	imageFileName: any = '';

	fileUploadTask = null;
	initMapFlag = false;

	validEmail = false;
	validCellNumber = true;
	profile_upload_percentage = '';
	percentage = '';
	loadingImageToCropper = false;

	fileChangeEvent(event: any): void {
		this.loadingImageToCropper = true;
		this.imageChangedEvent = event;
		this.file = event.target.files[0];
	}



	imageCropped(event: ImageCroppedEvent) {
		this.data.image = this.croppedImage = event.base64;
	}

	imageLoaded(image: HTMLImageElement) {
		// show cropper	
		this.loadingImageToCropper = false;
		this.data.image = image;
	}

	cropperReady() {
		// cropper ready
	}

	loadImageFailed() {
		// show message
		this.loadingImageToCropper = false;
	}

	constructor(
		//private _mapsAPILoader: MapsAPILoader,
		private _ngZone: NgZone,
		private router: Router,
		private accountService: AccountService,
		private sitesService: SitesService,
		private afAuth: AngularFireAuth,
		public peopleService: PeopleService,
		private activatedRoute: ActivatedRoute,
		private afDatabase: AngularFireDatabase,
		private notificationsService: NotificationsService,
		private titleService: Title,
		private db: AngularFireDatabase) {
		titleService.setTitle('Wissle Sign Up');
		this.activatedRoute.params.subscribe(params =>
			this.joinSiteCode = params['code']);

		this.data = {};
	}

	ngOnInit() {
		this.neighborhoodCreateUrl = this.activatedRoute.snapshot.queryParams['forwardUrl'] || '/';
		this.communityType = this.activatedRoute.snapshot.queryParams['communityType'] || 'essential';
		this.subscriptionType = this.activatedRoute.snapshot.queryParams['subscriptionType'] || 'none';
		this.inviteSiteKey = this.activatedRoute.snapshot.queryParams['inviteSiteKey'] || '';
		this.createdBy = this.activatedRoute.snapshot.queryParams['createdBy'] || '';

		//console.log('URL', this.neighborhoodCreateUrl);
		//console.log('community ', this.communityType);

		if (this.accountService.currentUser) {

			//console.log()
			this.accountService.signout();
			window.location.reload();
		}

		if (this.joinSiteCode != undefined) {
			let promises = Promise.all(
				[
					this.accountService.getSiteJoinKey(this.joinSiteCode, this.joinObj)
				]
			);

			promises.then(() => {
				if (this.joinObj.siteKey != '') {
					this.accountService.getSiteName(this.joinObj.siteKey, this.siteObj);
				}
			}, error => {
				alert(error);
			});
		}



		this.invitationRefKey = 'New Signup';


	}

	initMap() {
		if (this.initMapFlag)
			return;

		this.initMapFlag = true;

		this.element = <HTMLInputElement>document.getElementById('pac-input');
		var placesToFind = new google.maps.places.SearchBox(this.element);
		// Listen for the event fired when the user selects a prediction and retrieve
		// more details for that place.
		let that = this;
		//let addressaddress = this.address;
		//var myplace;
		placesToFind.addListener('places_changed', function () {
			var places = placesToFind.getPlaces();

			if (places.length == 0) {
				return;
			}
			//myplace = thisthis.getFormattedAddress(places[0]);   
			that.address = places[0].formatted_address;
			that.getAddress(places[0]);
		});
	}

	getAddress(e) {
		//console.log(e)
		this.geocoder = new google.maps.Geocoder;
		this.address = e.formatted_address;

		this.geocoder.geocode({ 'address': e.formatted_address }, (results, status) => {

			if (status == google.maps.GeocoderStatus.OK && results.length > 0) {
				this._ngZone.run(() => {
					var i;

					for (i = 0; i < results[0].types.length; i++) {
						if (results[0].types[i] == 'street_address' ||
							results[0].types[i] == 'premise') {

							this.addressValid = true;
							break;
						}
					}
					this.latitude = results[0].geometry.location.lat();
					this.longitude = results[0].geometry.location.lng();
					console.log('map');
					this.showMap = true;
					this.saving = false;
				});
			} else {
				alert('Geocode was not successful for the following reason: ' + status);
			}
		});
	}

	markerDragEnd(e) {
		this.latitude = e.coords.lat;
		this.longitude = e.coords.lng;
	}

	addressChange($event) {
		this.addressValid = false;
	}

	updateProfileImage(file: File, data: string, uid: string) {
		data = data.replace(`data:image/png;base64,`, '');
		let storage = firebase.storage();
		let storageRef = storage.ref();

		let fileStorageRef = storageRef.child('profile-images').child(uid);
		this.fileUploadTask = fileStorageRef.putString(
			data,
			'base64',
			{ customMetadata: { updatePath: `people/${uid}/profileImage` }, cacheControl: 'max-age=31536000', contentType: file.type });

		console.log("bytes transferred: " + this.fileUploadTask.snapshot.bytesTransferred);
		var $this = this;
		this.fileUploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
			function (snapshot) {
				//debugger;
				// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
				var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				console.log('Upload is ' + progress + '% done');

				$this.percentage = Math.round(progress) + '%';
				$this.profile_upload_percentage = 'Completing your profile : ';
				//console.log($this.profile_upload_percentage );
				// if(progress == 100){
				// 	this.isSaving = false;
				// }
				//debugger;
				//   this.zone.run(() => {
				// 	this.uploadProgress = progress;
				//   });

				switch (snapshot.state) {
					case firebase.storage.TaskState.PAUSED: // or 'paused'
						console.log('Upload is paused');
						break;
					case firebase.storage.TaskState.RUNNING: // or 'running'
						console.log('Upload is running');
						break;
				}
			}, function (error) {

				// A full list of error codes is available at
				// https://firebase.google.com/docs/storage/web/handle-errors
				switch (error.message) {
					case 'storage/unauthorized':
						// User doesn't have permission to access the object
						break;

					case 'storage/canceled':
						// User canceled the upload
						break;

					case 'storage/unknown':
						// Unknown error occurred, inspect error.serverResponse
						break;
				}

			});

		return this.fileUploadTask
			.then(snapshot => {
				return snapshot.task.then(uploadTask => {
					console.log("bytes transferred: " + this.fileUploadTask.snapshot.bytesTransferred);
					return uploadTask.ref.getDownloadURL().then(downloadURL => {
						return this.db.object(this._peopleRef.child(this.accountService.currentUser.uid).child('profileImage'))
							.update({
								downloadURL: downloadURL,
								downloadURLOriginal: downloadURL
							});
					});
				});
			});
	}

	accept() {
		this.waitingModal.show();
		this.isSaving = true;
		this.afAuth.auth.createUserWithEmailAndPassword(this.email, this.password)

			.then(user => {
				if (user) {
					let displayName = (this.firstName || this.lastName) ?
						`${this.firstName} ${this.lastName}` : this.email;

					this.accountService.setProfileCompleted(user.user.uid, true);
					this.afAuth.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
					this.newSetup(user.user.uid, this.firstName, this.lastName, this.email, this.address, this.cellNumber, 'assets/images/default-profile.png', this.neighborhoodCreateUrl);

					//this.isSaving = false;
				}
			}, error => {
				alert(error.message);
				this.waitingModal.hide();
				this.isSaving = false;
			});
	}

	async submit() {
		const emailIsValid = await this.isEmailValid(this.email);
		const phoneIsValid = this.isPhoneValid(this.cellNumber);

		if (emailIsValid && phoneIsValid) {

			this.terms.show();
			this.initAgreeButton()
		}
	}

	async isEmailValid(mail) {
		var mailformat = /\S+@\S+\.\S+/; ///^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (!mail.match(mailformat)) {
			alert('Email format is invalid.');
			return false;
		}

		const providers = await this.afAuth.auth.fetchSignInMethodsForEmail(mail);

		if (providers.length > 0) {
			alert('Email is already being used by another user. Please choose a different Email address.');
			return false;
		}

		return true;
	}

	isPhoneValid(cell: string) {
		var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
		if (cell.match(phoneno)) {
			return this.validCellNumber = true;
		}
		else {
			alert('Phone number is invalid. Enter it in the format of (###) ###-####.');
			return this.validCellNumber = false;
		}
	}

	showPhoneOrEmailError() {
		if (!this.validCellNumber)
			alert('Phone number is invalid. Enter it in the format of (###) ###-####.');
		else
			alert('Email format is invalid.');
	}

	changeCellNumberFormat(cell) {
		console.log(cell);
		cell = cell.replace('(', '');
		cell = cell.replace(')', '');
		cell = cell.replace(' ', '-');
		//debugger;
		return cell;
	}

	facebookSignup() {
		this.waitingModal.show();
		var provider = new firebase.auth.FacebookAuthProvider();
		firebase.auth().signInWithPopup(provider).then((result) => {
			this.completeSetup(this.afAuth.auth.currentUser.displayName,
				this.afAuth.auth.currentUser.photoURL,
				this.afAuth.auth.currentUser.email,
				this.cellNumber);
		}, error => {
			alert(error.message);
			this.waitingModal.hide();
		});
	}

	googleSignup() {
		this.waitingModal.show();
		let provider = new firebase.auth.GoogleAuthProvider();
		firebase.auth().signInWithPopup(provider).then((result) => {
			//console.log(result)
			this.completeSetup(this.afAuth.auth.currentUser.displayName,
				this.afAuth.auth.currentUser.photoURL,
				this.afAuth.auth.currentUser.email,
				this.cellNumber);
		}, error => {
			alert(error.message);
			this.waitingModal.hide();
		});
	}

	newSetup(uid: string, firstName: string, lastName: string, email: string, address: string, cell: any, photoURL, neighborhoodCreateUrl) {
		var signup = this;
		var groupObj = { count: 0 };
		var emailRef = encodeURIComponent(btoa(email));

		// format the cell phone back to ###-###-####
		cell = this.changeCellNumberFormat(cell);

		this.afAuth.auth.currentUser.updateEmail(email)
			.then(() =>

				this.afAuth.auth.currentUser.updateProfile({
					displayName: firstName + ' ' + lastName,
					photoURL: photoURL
				}).then(() => {
					this.sitesService.signUpNewUser(uid,
						firstName,
						lastName,
						email,
						address,
						cell
					).then(() => {

						let promises = [];

						if (this.address.length) {
							promises.push(this.accountService.updateAddress(this.address, this.latitude, this.longitude));
						}

						if (this.data.image) {
							promises.push(this.updateProfileImage(this.file, this.data.image, uid));
						}

						var persistenceType = firebase.auth.Auth.Persistence.LOCAL;  // first time the user is created they should stayed logged in
						promises.push(firebase.auth().setPersistence(persistenceType));

						Promise.all(promises)
							.then(() => {
								if ((this.inviteSiteKey != '' &&
									this.createdBy != '') ||
									this.joinObj.siteKey != '') {

									if (this.joinObj.siteKey != '') {
										this.sitesService.addMember(this.accountService.currentUser.uid, this.joinObj.siteKey, false);
										this.sitesService.removeNonMemberByEmail(this.joinObj.siteKey, this.accountService.currentUser.email);
									}
									else {
										this.sitesService.addMember(this.accountService.currentUser.uid, this.inviteSiteKey, false);
										this.sitesService.removeNonMemberByEmail(this.inviteSiteKey, this.accountService.currentUser.email);
									}

									groupObj.count = 1;
									this.sitesService.updateUserGroupStatus(this.accountService.currentUser.uid, groupObj);

									if (this.joinObj.siteKey == '') {
										this.notificationsService.removeNotification(this.inviteSiteKey, this.createdBy);
										this.accountService.setCurrentSite(this.inviteSiteKey);
									}
									else {
										this.accountService.setCurrentSite(this.joinObj.siteKey);
									}

									this.router.navigate(['neighborhood/members'], { queryParams: { welcome: 1 } });
								}
								else {
									this.accountService.setCurrentSite(uid);
									if (neighborhoodCreateUrl != '/')
										this.router.navigate(['neighborhood/create'], { queryParams: { communityType: this.communityType, subscriptionType: this.subscriptionType, newuser: 1 } });
									else
										this.router.navigate(['neighborhood/create']);
								}


							}, (error) => {
								alert(error);
							});
					});
					//promises.push(this.accountService.updateCellPhone(this.cellPhone));
				}, error => {
					alert(error);
					this.waitingModal.hide();
				}));

		if ((this.inviteSiteKey != '' &&
			this.createdBy != '') ||
			this.joinObj.siteKey != '') {
			// do nothing
		}
		else {
			let ref = firebase.database().ref(`signup-invitation/${emailRef}`).once('value');
			ref.then(function (snapshot) {

				if (snapshot.val() != null) {
					var invited_sitename = snapshot.val().invited_sitename;
					var message = snapshot.val().message;
					var invited_site_key = snapshot.val().invited_site_key;
					var createdBy = snapshot.val().createdBy;
					var siteType = snapshot.val().siteType;
					var memberKeys = [uid];
					var site = snapshot.val();
					signup.notificationsService.afterSignUpNotification(site, invited_sitename, message, uid, invited_site_key, createdBy, siteType);
				}

			});
		}
	}

	initAgreeButton() {
		this.agreeButtonPressed = false;
		this.isSaving = false;
	}


	completeSetup(displayName: string, photoURL, email,
		cellNumber?: string, address?: string) {


		this.sitesService.getCurrentNeighborhood()
			.subscribe(siteKey => this.router.navigate(['edit-profile']));

		this.afAuth.auth.currentUser.updateEmail(email)
			.then(() =>
				this.afAuth.auth.currentUser.updateProfile({
					displayName: displayName ? displayName : email,
					photoURL: photoURL
				}).then(() => {
					this.sitesService.completeSignup(this.invitationRefKey,
						displayName,
						email, photoURL, cellNumber, address)
						.then(() => {
							this.sitesService.getInvitationSiteKey(this.invitationRefKey)
								.then((siteKey) => {
									this.sitesService.getCurrentMemberCount(siteKey, this.memberCountObj, this.groupTypeObj)
										.then((memberCount) => {
											// finally, update the group count									
											this.sitesService.updateMemberCount(siteKey, memberCount + 1);

											this.groupObj.count++;  // maybe have to check how many groups they belong to but this is for a new user so it should start with "1"									
											this.sitesService.updateUserGroupStatus(this.afAuth.auth.currentUser.uid, this.groupObj); // doesn't matter if it's community or group, group_status gets upated							
										});
								})
						});
				}, error => {
					alert(error);
					this.waitingModal.hide();
				}));
	}


}
