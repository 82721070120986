import { Injectable } from '@angular/core';
declare var google: any;

@Injectable()
export class GeocodeService {
    private geocoder: any;

    private lastLocation: any;

    constructor() {
        setTimeout(() => {
            this.geocoder = new google.maps.Geocoder;
        }, 5000);

        this.lastLocation = localStorage['lastLocation'] ? JSON.parse(localStorage['lastLocation']) : null;
    }

    getCurrentPosition(): Promise<Position> {
        return new Promise((resolve, reject) => {
            if (navigator) {
                navigator.geolocation.getCurrentPosition(position => {
                    resolve(position)
                }, error => reject(error.message), {
                        maximumAge: 1000 * 60
                    })
            }
            else {
                reject('No location')
            }
        });
    }

    getLatLngFromAddress(address: string): Promise<Location> {
        return new Promise((resolve, reject) => {
            this.geocoder.geocode({ 'address': address }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    var location = new Location();
                    location.address = results[0].formatted_address;
                    location.latitude = results[0].geometry.location.lat();
                    location.longitude = results[0].geometry.location.lng();
                    resolve(location);
                } else {
                    reject(results);
                }
            });
        });
    }

    getCurrentLocation(): Promise<Location> {
        return new Promise((resolve, reject) => {
            if (this.lastLocation) {
                resolve(this.lastLocation);
            }
            else {
                this.getCurrentPosition()
                    .then(position => {
                        this.getAddressFromPosition(position.coords.latitude, position.coords.longitude)
                            .then(
                            address => {
                                var location = new Location();
                                location.address = address.formatted_address;
                                location.latitude = position.coords.latitude
                                location.longitude = position.coords.longitude
                                this.lastLocation = location;
                                localStorage['lastLocation'] = JSON.stringify(location);
                                resolve(location);
                            })
                    })
            }
        });
    }

    getAddressFromPosition(lat, lng): Promise<any> {
        return new Promise((resolve, reject) => {
            this.geocoder.geocode({ 'location': { lat: lat, lng: lng } }, function (results, status) {
                if (status === 'OK') {
                    if (results[0]) {
                        resolve(results[0])
                    } else {
                        reject('No results found');
                    }
                } else {
                    reject('Geocoder failed due to: ' + status);
                }
            });
        });
    }
}

export class Location {
    public latitude: number;
    public longitude: number;
    public address: string;
}