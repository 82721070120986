import { ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { Component, OnInit, AfterViewInit, Output, EventEmitter, ViewChild, TemplateRef, ElementRef, AfterContentInit } from '@angular/core';

@Component({
    selector: 'app-routed-modal',
    templateUrl: './routed-modal.component.html',
    styleUrls: ['./routed-modal.component.css'],
    exportAs: 'modal'
})
export class RoutedModalComponent implements OnInit, AfterViewInit, AfterContentInit {

    @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();
	@ViewChild(ModalDirective) modal: ModalDirective;
	@ViewChild('header') header: ElementRef;
	@ViewChild('footer') footer: ElementRef;

	headerIsVisible = true;
	footerIsVisible = true;

    constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
		
	}

	ngAfterContentInit() {
		this.headerIsVisible = this.header.nativeElement.children.length > 0 && this.header.nativeElement.children[0].className == 'header';
		this.footerIsVisible = this.footer.nativeElement.children.length > 0 && this.footer.nativeElement.children[0].className == 'footer';
    }

    ngAfterViewInit() {
        this.modal.show();
        this.modal.onHide.subscribe(() => {
            this.router.navigate(['.', { outlets: { modal: null } }], { relativeTo: this.activatedRoute.parent });
		});
		
    }

    closeModal($event) {
        //this.router.navigate([{ outlets: { modal: null } }]);
        this.modalClose.next($event);
        this.modal.hide();
    }

}
