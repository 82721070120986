import { Router} from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, isDevMode} from '@angular/core';
import { CheckOutService} from 'app/core/services/checkout.service';
import { AccountService } from "app/core/services/account.service";
import { ModalComponent } from "app/shared/modal/modal.component";

@Component({
	selector: 'app-subscription',
	templateUrl: './subscription.component.html',
	styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {

	@ViewChild('upgradeModal') upgradeModal: ModalComponent;
	// these are sent by the parent component neighborhood-create
	@Input() childCommunity: boolean;	
	@Input() childGroup: boolean;	

	@Input() childEssential: boolean;
	@Input() childEssentialFull: boolean;


	@Input() childPlus: boolean;
	@Input() childPlusFull: boolean;

	@Input() childPremium: boolean;
	@Input() childPremiumFull: boolean;
	
	message: string = "THE ESSENTIAL PACKAGE!";  // sent to neighborhood create component

	@Output() messageEvent = new EventEmitter<string>();
	  
	website = 'https://wissle.com';
	email = '';

    constructor(private afAuth: AngularFireAuth,
		        private router: Router,
				private checkoutService: CheckOutService,
				private accountService: AccountService) { }

	sendMessage() {
		this.messageEvent.emit(this.message)
	}

	ngOnInit() {		
		if (isDevMode())
			//this.website = 'http://localhost:4200/';	
			this.website = 'https://wissle.com';
			
		this.accountService.getUserDetails()
			.valueChanges()
			.subscribe((details: any) => {
				;
				this.email = details.email;
			});
	}

	subscribed(){
/*  	this.upgradeModal.show()   */
	 alert('You have reached the maximum level of this subscription plan! Please upgrade your account or leave another group to create a new group.'); 
	}
	
	async subscribePlus() {	
		this.checkoutService.setSubscription('plus');	                                                                  
        this.accountService.subscribeTo('price_1I49HRDZWXSMJjj8e2lZONBl', this.website); 		
	}

	async subscribePremium() {		
		this.checkoutService.setSubscription('premium');	                                                                  
        this.accountService.subscribeTo('price_1I49HLDZWXSMJjj8dfk3A5zH', this.website);		
	}

	async subscribeSmall() {					
		this.checkoutService.setSubscription('small');	
		this.accountService.subscribeTo('price_1I49GBDZWXSMJjj8yOCqsufJ', this.website);					
	}

	async subscribeMedium() {					
		this.checkoutService.setSubscription('medium');				
		this.accountService.subscribeTo('price_1I49GkDZWXSMJjj8vzw2mjvt', this.website);
	}

	async subscribeLarge() {					
		this.checkoutService.setSubscription('large');				
		this.accountService.subscribeTo('price_1I49HADZWXSMJjj8FDgAmSEl', this.website);
	}
}
