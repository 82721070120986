import { AlbumsService } from './albums.service';
import { AccountService } from './account.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule
	],
	declarations: [],
	providers: [AccountService, AlbumsService,]
})
export class ServicesModule { }
