import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SitesService } from "app/core/services/sites.service";

@Component({
    selector: 'app-neighborhood-detail-edit',
    templateUrl: './neighborhood-detail-edit.component.html',
    styleUrls: ['./neighborhood-detail-edit.component.css']
})
export class NeighborhoodDetailEditComponent implements OnInit {
    @Input() currentSite;
    @Output() close = new EventEmitter();
    name = '';
    description = '';

    constructor(private sitesService: SitesService) { }

    ngOnInit() {
        // this.sitesService.getCurrentNeighborhood()
        //     .subscribe(site => {
        //         this.name = site.name;
        //         this.description = site.description;
        //     });
    }

    ngAfterViewInit() {
        // 
        // //console.log(this.currentSite)
    }

    submit() {
        this.sitesService.updateDetails(this.currentSite.siteKey, this.currentSite.name, this.currentSite.description, this.currentSite.typeGroup);
        this.close.emit();
    }
    cancel() {
        this.close.emit();
    }
}
