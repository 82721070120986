import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutorial-start-group',
  templateUrl: './tutorial-start-group.component.html',
  styleUrls: ['./tutorial-start-group.component.css']
})
export class TutorialStartGroupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
