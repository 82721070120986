
import {take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from "angularfire2/database";
import { AngularFireAuth } from "angularfire2/auth";
import * as firebase from 'firebase';
import { NotificationsService } from "app/notifications/notifications.service";
import { map } from 'rxjs/operators';

@Injectable()
export class ReactionsService {

	private _reactions = this.db.database.ref('reactions');
	private _postItemRef = this.db.database.ref(`posts`);
	private _commentItemRef = this.db.database.ref(`comment-items`);

	constructor(private db: AngularFireDatabase, 
				private afAuth: AngularFireAuth,
				private notificationsService: NotificationsService) { }

	add(parentKey: string) {
		debugger;
		this.db.object(this._reactions.child(parentKey).child(this.afAuth.auth.currentUser.uid)).update({
			createdAt: firebase.database.ServerValue.TIMESTAMP
		});

		var text = "Your alert was useful.";
		let comment = {
			text: text,
			createdBy: this.afAuth.auth.currentUser.uid,
			createdAt: firebase.database.ServerValue.TIMESTAMP,
			createdByName: this.afAuth.auth.currentUser.displayName ,
			albumKey: null,
			sortSequence: 0 - Date.now()
		};

		let ref = this.db.list(this._commentItemRef).push(comment);
		this.db.object(this._postItemRef.child(parentKey))
			.valueChanges().pipe(take(1)).subscribe(record => {				
				var nameArr = [];
				var objects = []
				var obj = new Object(); //record;
				obj = record;
				objects.push(obj);
				nameArr.push(objects[0].createdBy);
				debugger;			
				this.notificationsService.create(
					text,
					ref.key,
					this.afAuth.auth.currentUser.photoURL,
					['/neighborhood/alerts', { outlets: { modal: ['view', parentKey] } }],
					nameArr,
					this.afAuth.auth.currentUser.displayName
				);
			})
	}

	remove(parentKey: string) {
		this.db.object(this._reactions.child(parentKey).child(this.afAuth.auth.currentUser.uid)).remove();
	}

	count(parentKey: string) {
		return this.db.list(this._reactions.child(parentKey))
			.snapshotChanges()
			.pipe(map(refs => refs.length));
	}

	reactedByYou(parentKey: string) {
		return this.db
			.object(this._reactions.child(parentKey).child(this.afAuth.auth.currentUser.uid))
			.snapshotChanges()
			.pipe(map(refs => refs.payload.exists()));
	}
}
