import { AccountService } from 'app/core/services/account.service';
import { AppEngineService } from './app-engine.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private ae: AppEngineService) { }

	canActivate(): Observable<boolean> | boolean {
		return Observable.create(observer => {
			this.ae.auth.onAuthStateChanged(user => {
				if (!user) {
					this.router.navigate(['/login']);
					observer.next(false);
				}
				else {
					this.ae.sites.getCurrentSiteKey()
						.valueChanges().subscribe(siteKey => {
							if (!siteKey) {
								this.ae.sites.getMySites().forEach(sites => {
									if (sites.length === 0) {
										this.router.navigate(['/no-site']);
										observer.next(false);
									} else {
										this.ae.sites.setCurrentSite(sites[0].key).then(() => {
											observer.next(true);
										})
									}
								});
							} else {
								observer.next(user != null);
							}
						});
				}
			});
		});
	}
}
