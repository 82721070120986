import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SitesService } from "app/core/services/sites.service";
import { ImageCroppedEvent } from 'ngx-image-cropper';
declare var $ :any;

@Component({
	selector: 'app-neighborhood-background-image-edit',
	templateUrl: './neighborhood-background-image-edit.component.html',
	styleUrls: ['./neighborhood-background-image-edit.component.css']
})
export class NeighborhoodBackgroundImageEditComponent implements OnInit {
	@Input() backgroundImage;
	@Input() currentSite;
	@Output() close = new EventEmitter();

	image: string;

	file: any;
	data: any;

	imageChangedEvent: any = '';
	croppedImage: any = '';
	imageFileName: any = '';

    fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;	
		var image:any = new Image();					
		this.file = event.target.files[0];
		
		var myReader:FileReader = new FileReader();
		var that = this;
		myReader.onloadend = function (loadEvent:any) {
			image.src = loadEvent.target.result;								
			that.data.image = image.src;						
		};
		myReader.readAsDataURL(this.file);
	}
	
    imageCropped(event: ImageCroppedEvent) {
		this.data.image = this.croppedImage = event.base64;
	}
	
    imageLoaded(image: HTMLImageElement) {
		// show cropper	
		this.data.image = image;	
	}
	
    cropperReady() {
		// cropper ready
	}
	
    loadImageFailed() {
		// show message
    }

	constructor(private sitesService: SitesService) {			
		this.data = {};		
	}

	ngOnInit() {
					
		this.croppedImage = this.data.image = this.backgroundImage;		
	}
	
	submit() {
		if (this.file) {			
			this.sitesService.updateBackgroundImage(this.currentSite.siteKey, this.file, this.data.image, true);
			this.close.emit();
		}
	}

	cancel() {		
		this.imageChangedEvent = null;				
		this.croppedImage = this.data.image = this.backgroundImage;					
		this.close.emit();
	}	

}
