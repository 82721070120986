import { Injectable, Output, EventEmitter } from '@angular/core';
import { BaseDiscussionService } from "app/core/services/base-discussion.service";
import { AngularFireDatabase } from "angularfire2/database";
import { AngularFireAuth } from "angularfire2/auth";
import { AccountService } from "app/core/services/account.service";
import { NotificationsService } from "app/notifications/notifications.service";
import { GroupsService } from "app/groups/groups.service";
import { SitesService } from 'app/core/services/sites.service';

@Injectable()
export class PostsService extends BaseDiscussionService {

	@Output() commentsSelected = new EventEmitter<string>();

	showOnMap = new EventEmitter<any>();

	constructor(private _db: AngularFireDatabase, private _afAuth: AngularFireAuth,
		private _accountSerivce: AccountService,
		private _notificationsService: NotificationsService,
		private _memberGroupsService: GroupsService,
		private _sitesService: SitesService) {

		super('post', _db, _afAuth, _accountSerivce, _notificationsService, _memberGroupsService, _sitesService);
	}

	createPost(isUrgent: boolean, text: string, shortMessage: string, postType: any, groupKey: string = null, longitude: number = null, latitude: number = null, address: string = null, tags: string[] = [], albumKey: string = null, titleColor: string = null) {
		
		return super.create(text, shortMessage,
			groupKey, {
				isUrgent: isUrgent,
				postType: postType,
				address: address,
				latitude: latitude,
				longitude: longitude
			}, tags, albumKey, titleColor, false);
	}

	getPostTypes() {
		return this._db.list(`post-types`);
	}

	editPost(text: string, key: string) {
		return super.edit(text, key);
	}

}
