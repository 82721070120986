import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-reset-password-sent',
    templateUrl: './reset-password-sent.component.html',
    styleUrls: ['./reset-password-sent.component.css']
})
export class ResetPasswordSentComponent implements OnInit {

    email = '';
    constructor(activatedRoute: ActivatedRoute) {
        activatedRoute.params.subscribe(params => this.email = params.email);
    }

    ngOnInit() {
    }

}
