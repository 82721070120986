import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-sub-menu',
  templateUrl: './side-sub-menu.component.html',
  styleUrls: ['./side-sub-menu.component.css']
})
export class SideSubMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
