import { Component, OnInit, Input } from '@angular/core';
import { GroupsService } from "app/groups/groups.service";

@Component({
	selector: 'app-groups-member-add',
	templateUrl: './groups-member-add.component.html',
	styleUrls: ['./groups-member-add.component.css']
})
export class GroupsMemberAddComponent implements OnInit {

	@Input() groupKey: string;

	uid: string;

	constructor(private groupsService: GroupsService) { }

	ngOnInit() {
	}

	submit() {
		this.groupsService.addMember(this.uid, this.groupKey, false);
	}
}
