import { AccountRoutingModule } from './account-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { ValidateEmailComponent } from './validate-email/validate-email.component';
import { AgmCoreModule } from '@agm/core';
import { BrowserModule } from '@angular/platform-browser';
import { NewUserComponent } from './new-user/new-user.component';
import { SignupComponent } from './signup/signup.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { QuickListModule } from 'app/quick-list/quick-list.module';
import { TooltipModule } from 'ngx-bootstrap';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { UpdateEmailComponent } from './update-email/update-email.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UpdateProfileImageComponent } from './update-profile-image/update-profile-image.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordSentComponent } from './reset-password-sent/reset-password-sent.component';
import { TabsModule } from 'ngx-bootstrap';
import { CompleteProfileComponent } from './complete-profile/complete-profile.component';
import { RlTagInputModule } from 'app/shared/tag-input/tag-input.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		AgmCoreModule.forRoot({ 
			libraries: ['places']
		}),
		TooltipModule.forRoot(),
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		AccountRoutingModule,
		QuickListModule,
		ImageCropperModule,
        RlTagInputModule,
		SharedModule,
		TabsModule.forRoot(),
		ImageCropperModule
     // GooglePlaceModule,
	],
	exports: [
		ValidateEmailComponent,
		CompleteProfileComponent
	],
	declarations: [
		ValidateEmailComponent, NewUserComponent,SignupComponent, UpdateProfileComponent, UpdatePasswordComponent, UpdateEmailComponent, UpdateProfileImageComponent, LoginComponent, ResetPasswordComponent, ResetPasswordSentComponent, CompleteProfileComponent]
})
export class AccountModule { }
