import { PeopleService } from './../people.service';
import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'app-person-details',
    templateUrl: './person-details.component.html',
    styleUrls: ['./person-details.component.css']
})
export class PersonDetailsComponent implements OnInit, OnChanges {

    @Input('key') key: string;

    person: any;

    constructor(private peopleService: PeopleService) { }

    ngOnInit() {
    }

    ngOnChanges() {
        this.person = this.peopleService.getPersonByKey(this.key).valueChanges();
        
    }

}
