
import { CamerasComponent } from './cameras/cameras.component'
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from "app/app.guard";
import { CamerasItemModalComponent } from "app/cameras/cameras-item-modal/cameras-item-modal.component"
import { CamerasItemCreateComponent } from "app/cameras/cameras-item-create/cameras-item-create.component"
import { CamerasItemEditComponent } from "app/cameras/cameras-item-edit/cameras-item-edit.component"
import { CamerasFullscreenComponent } from "app/cameras/cameras-fullscreen/cameras-fullscreen.component"

const routes: Routes = [
    
    { path: 'neighborhood/cameras-fullscreen', component: CamerasFullscreenComponent},
    { path: 'neighborhood/cameras', component: CamerasComponent, canActivate: [AuthGuard],
    children: [
        {
            path: 'view/:key',
            component: CamerasItemModalComponent,
            outlet: 'modal'
        },
        {
            path: 'create/:key',
            component: CamerasItemCreateComponent,
            outlet: 'modal'
        },
        {
            path: 'create',
            component: CamerasItemCreateComponent,
            outlet: 'modal'
        },
        {
            path: 'edit/:key',
            component: CamerasItemEditComponent,
            outlet: 'modal'
        }
] },
    { path: 'neighborhood/cameras/:key', component: CamerasComponent}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CamerasRoutingModule { }
