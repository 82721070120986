import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CommentsService } from "app/comments/comments.service";
import { AccountService } from "app/core/services/account.service";
import { UploaderComponent } from 'app/files/uploader/uploader.component';

@Component({
	selector: 'app-comment-item-create',
	templateUrl: './comment-item-create.component.html',
	styleUrls: ['./comment-item-create.component.css']
})
export class CommentItemCreateComponent implements OnInit {

	@ViewChild(UploaderComponent) uploader: UploaderComponent;

	@Input() parentKey: string;
	@Input() category: string;
	isAuthenticated = false;
	comment = '';
	loading = false;

	constructor(private commentsService: CommentsService,public accountService: AccountService) { }

	ngOnInit() {
		
		this.accountService.isAuthenticated.subscribe(isAuthenticated => {
			this.isAuthenticated = isAuthenticated;
		});
	}

	autoGrowTextZone(e) {
		e.target.style.height = "0px";
		e.target.style.height = (e.target.scrollHeight + 25)+"px";
	  }
	submit() {
		
		this.loading = true;
		this.commentsService.create(this.parentKey, this.comment, this.category, this.uploader.albumKey)
			.then(ref => { 
				this.comment = '';
				this.loading = false;
				this.uploader.clear();
			}, error => {
				this.loading = false;
			});
		
	}

}
