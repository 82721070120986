import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SitesService } from "app/core/services/sites.service";
import { DragulaService } from 'ng2-dragula/ng2-dragula';

@Component({
	selector: 'app-neighborhood-documents-edit',
	templateUrl: './neighborhood-documents-edit.component.html',
	styleUrls: ['./neighborhood-documents-edit.component.css']
})
export class NeighborhoodDocumentsEditComponent implements OnInit {

	@Output() close = new EventEmitter();

	documents: any[];

	title: string = '';
	file: File;

	constructor(private sitesService: SitesService,
		private dragula: DragulaService) { }

	ngOnInit() {
		this.sitesService.getDocumentList()
			.subscribe(documents => {
				this.documents = [];
				documents.forEach(document => this.documents.push(document));
			});


	}

	fileSelected(event) {
		this.file = event.srcElement.files.length > 0 ? event.srcElement.files[0] : null;
	}

	add() {
		if (this.file) {
			this.sitesService.addDocument(this.documents.length, this.file, this.title);
			this.file = null;
			this.title = '';
		}
	}

	removeItem(document) {
		if (confirm('Remove document?')) {
			this.sitesService.removeDocument(document.$key);
		}
	}

	update() {
		this.documents.forEach((document, index) => {
			document.order = index;
		});
		this.close.emit();
	}
	cancel() {
		this.close.emit();
	}
}
